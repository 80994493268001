import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Select from 'antd/lib/select';
import { Button, Checkbox, Col, Input, Popover, Row, Space, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import selects from 'helpers/styles/components/selects.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { DispatchType, Redux } from 'helpers/types/_common';
import { savePersonalInfoFields } from 'redux/PersonalInfo/action';
import { PersonalInfoFields, PersonalInfoResponseError } from 'api/PersonalInfo/types';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import { ReactComponent as EditIcon } from 'helpers/icons/Buttons/edit-icon.svg';
import OCCUPATIONS from 'helpers/constants/Occupations/Occupations';
import { getDaDataLocation } from 'api/DaData/requests';
import { UserRedux } from 'redux/User/types';
import useApi from 'api/hooks/useApi/useApi';
import { TIMEZONES_LABEL, TIMEZONES_SHORT, TimezonesList } from 'api/DaData/timezones';
import { DaDataAddressResponse } from 'api/DaData/types';
import { SKILL_EXPERIENCES_COLOR_RAW } from 'helpers/constants/Skills/Experience';
import { EntityOutput, UserDetails } from 'api/User/types';
import useErrorForm from 'helpers/hooks/useErrorForm/useErrorForm';
import { EXTRA_PERMISSIONS, USER_TYPES } from 'helpers/constants/_common/constants';
import { getEntities } from 'api/Entities/requests';
import { EntityPage } from 'api/Entities/types';
import { AccessEnum } from 'components/Root/helpers/types';

import SkillTag from '../../../_shared/SkillTag';
import SelectLazySkills from './components/SelectLazySkills';
import { InitialUserValues } from './helpers/types';
import s from './helpers/styles.module.sass';
import { INCREASED_PAGINATION_RAW } from '../../../../helpers/constants/_common/Pagination';
import useDebounce from '../../../../helpers/hooks/useDebounce';

interface PersonalInfoProps {
  user: UserDetails | UserRedux;
  setUserData?: React.Dispatch<UserDetails>;
  editByAdmin?: boolean;
  curatorOnlyView?: boolean;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ user, editByAdmin, setUserData, curatorOnlyView }) => {
  const dispatch: DispatchType = useDispatch();

  const [ getLocationBySearch, locationList ] = useApi<typeof getDaDataLocation, DaDataAddressResponse[]>(getDaDataLocation);
  const [ getEntitiesList, paginatedEntities, loadingEntities ] = useApi<typeof getEntities, EntityPage>(getEntities);
  const loggedUser = useSelector((state: Redux) => state.login);

  const [ isEdit, setEdit ] = useState(false);
  const [ saveLoading, setSaveLoading ] = useState(false);
  const [ changedUserValues, setChangedUserValues ] = useState<InitialUserValues>({});
  const [ searchEntities, setSearchEntities ] = useState<string>();

  const debounceSearchEntities = useDebounce(searchEntities, 600);

  const [ errorHelpers, setErrors ] = useErrorForm<PersonalInfoResponseError>();

  const isReadOnlyMode = curatorOnlyView || (editByAdmin && !_.includes(loggedUser.permissions, AccessEnum.Admin));

  const extraPermissionsAmount = useMemo(() => _.reduce(EXTRA_PERMISSIONS, (result, permissionName, permissionKey) => {
    if (changedUserValues[permissionKey as keyof InitialUserValues] as boolean) return result + 1;

    return result;
  }, 0), [ changedUserValues ]);

  useEffect(() => {
    setChangedUserValues(user);
  }, [ user ]);

  useEffect(() => {
    if (!isReadOnlyMode && editByAdmin) {
      getEntitiesList({
        nameContains: debounceSearchEntities,
        ...INCREASED_PAGINATION_RAW,
      });
    }
  }, [ debounceSearchEntities ]);

  const handleFieldsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName?: string) => {
    const { currentTarget } = event;

    const name = currentTarget.name || fieldName;

    if (!name) {
      return;
    }

    errorHelpers.doValidateByFieldChanging(name as keyof PersonalInfoResponseError, currentTarget.value, event);

    setChangedUserValues({ ...changedUserValues, [name]: _.isNumber(currentTarget.value) ? currentTarget.value : currentTarget.value });
  };

  const handleOnEditSwitch = () => {
    setEdit(!isEdit);
  };

  const handleOnCancel = () => {
    setChangedUserValues(user);
    errorHelpers.clearErrorsState();
    setEdit(!isEdit);
  };

  const handleChangeUserValues = (value: any, fieldName: keyof InitialUserValues) => {
    setChangedUserValues({ ...changedUserValues, [fieldName]: value });
  };

  const handleOnSave = () => {
    const newValues = _.reduce(changedUserValues, (acc, value, key) => {
      const trimmedValue = _.isString(value) ? _.trim(value) : value;

      if (user[key as keyof (UserRedux | UserDetails)] !== trimmedValue) {
        return {
          ...acc,
          [key]: trimmedValue,
        };
      }
      return acc;
    }, {});

    if (_.isEmpty(newValues)) {
      setEdit(!isEdit);
      return;
    }

    setSaveLoading(true);

    const validValues = {
      ..._.omit(newValues, 'entities'),
      ...('entities' in newValues ? { entityIds: _.map(_.get(newValues, 'entities'), (entityObj: EntityOutput) => entityObj.entityId) } : {}),
    };

    dispatch(savePersonalInfoFields({ ...validValues }, user.userId, editByAdmin))
      .then(() => {
        setUserData?.({ ...user, ...newValues });
        setEdit(false);
        errorHelpers.clearErrorsState();
      })
      .catch((errorFields: PersonalInfoResponseError) => {
        setErrors(errorFields);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const onSearchCity = _.debounce((address: string) => {
    if (!address && changedUserValues.location) {
      getLocationBySearch(changedUserValues.location);
      return;
    }

    getLocationBySearch(address);
  }, 500);

  const renderFieldsWithErrors = (fieldName: keyof PersonalInfoResponseError, isRequired?: boolean, minMax?: { min: number, max: number }) => {
    const fieldValue = changedUserValues[fieldName as keyof PersonalInfoFields];

    if (!isEdit && isRequired && !fieldValue) {
      return (
        <Space className={s.notSpecified} align="center">
          <FontAwesomeIcon icon="exclamation-circle" style={{ fontSize: 20 }} />
          Not specified
        </Space>
      );
    }

    return (
      <>
        <Input
          onChange={handleFieldsChange}
          name={fieldName}
          min={minMax?.min}
          max={minMax?.max}
          required={isRequired}
          title={changedUserValues[fieldName as keyof Omit<PersonalInfoFields, 'primarySkill' | 'entityIds'>]?.toString()}
          value={changedUserValues[fieldName as keyof Omit<PersonalInfoFields, 'primarySkill' | 'active' | 'usertypeId' | 'markedSkillsetAsActual' | 'entityIds'>]}
          className={cx(inputs.qsInput, s.profileInput, { [inputs.error]: errorHelpers.isErrorField(fieldName) })}
          disabled={!isEdit}
          style={{ textOverflow: 'ellipsis' }}
        />
        {errorHelpers.renderError(fieldName)}
      </>
    );
  };

  const renderTextAreaFieldWithErrors = (fieldName: keyof PersonalInfoResponseError) => (
    <>
      <TextArea
        onChange={handleFieldsChange}
        name="description"
        maxLength={2047}
        placeholder="Not fulfilled"
        className={cx(inputs.trackerTextArea, s.profileTextarea, { [s.profileInputError]: errorHelpers.isErrorField(fieldName) })}
        value={changedUserValues.description}
        disabled={!isEdit}
      />
      {errorHelpers.renderError(fieldName)}
      <small className={s.profileTextareaDescription}>
        { isEdit ? 'Briefly describe yourself (ex. your experience or participation in projects)' : '' }
      </small>
    </>
  );

  const renderOccupations = () => {
    const options = _.map(Object.keys(OCCUPATIONS), key => <Select.Option key={key} value={key}>{OCCUPATIONS[key].name}</Select.Option>);

    if (!isEdit && !changedUserValues.occupation) {
      return (
        <Space direction="vertical" size={8} style={{ width: '100%' }}>
          <span className={cx(s.profileLabel, { [s.required]: isEdit })}>Position</span>
          <Space className={s.notSpecified} align="center">
            <FontAwesomeIcon icon="exclamation-circle" style={{ fontSize: 20 }} />
            Not specified
          </Space>
        </Space>
      );
    }

    return (
      <Space direction="vertical" size={8} style={{ width: '100%' }}>
        <span className={cx(s.profileLabel, { [s.required]: isEdit })}>Position</span>
        <Select
          placeholder={isEdit ? 'Choose position...' : 'Not specified'}
          onChange={value => handleChangeUserValues(value, 'occupation')}
          className={cx(selects.qsSelect, s.profileSelect)}
          dropdownClassName={selects.selectDropdown}
          bordered={false}
          size="large"
          value={changedUserValues.occupation}
          suffixIcon={<SelectIcon />}
          disabled={!isEdit}
        >
          {options}
        </Select>
      </Space>
    );
  };

  const renderMainSkill = () => {
    if (!isEdit && !_.isEmpty(changedUserValues?.primarySkill)) {
      return (
        <Space direction="vertical" size={8} style={{ width: '100%' }}>
          <span className={s.profileLabel}>Main Skill</span>
          <SkillTag
            className={s.profileMainSkill}
            title={changedUserValues.primarySkill?.skill.name}
            color={changedUserValues?.primarySkill && SKILL_EXPERIENCES_COLOR_RAW[changedUserValues?.primarySkill?.experience]}
          />
        </Space>
      );
    }

    return (
      <Space direction="vertical" size={8} style={{ width: '100%' }}>
        <span className={s.profileLabel}>Main Skill</span>
        <SelectLazySkills
          userId={user.userId}
          isEdit={isEdit}
          onChangePosition={handleChangeUserValues}
          userValues={changedUserValues}
          editByAdmin={editByAdmin}
        />
      </Space>
    );
  };

  const renderTimezoneSelect = () => {
    if (!isEdit && _.isNil(changedUserValues.timezoneUtcOffset)) {
      return (
        <Space className={s.notSpecified} align="center">
          <FontAwesomeIcon icon="exclamation-circle" style={{ fontSize: 20 }} />
          Not specified
        </Space>
      );
    }

    const timezoneOptions = (timezoneList: TimezonesList) => _.map(timezoneList, t => (
      <Select.Option
        value={t.value}
        key={t.label}
        title={t.label}
      >
        {t.label}
      </Select.Option>
    ));

    return (
      <Select
        placeholder={isEdit ? 'Choose timezone...' : 'Not specified'}
        onChange={value => handleChangeUserValues(value, 'timezoneUtcOffset')}
        className={cx(selects.qsSelect, s.profileSelect)}
        dropdownClassName={selects.selectDropdown}
        bordered={false}
        size="large"
        showSearch
        value={changedUserValues.timezoneUtcOffset}
        suffixIcon={<SelectIcon />}
        disabled={!isEdit}
        autoClearSearchValue
      >
        <Select.OptGroup label="Popular">
          {timezoneOptions(_.pick(TIMEZONES_SHORT, [ TIMEZONES_LABEL.moscow, TIMEZONES_LABEL.novosibirsk ]))}
        </Select.OptGroup>
        <Select.OptGroup label="Others">
          {timezoneOptions(_.omit(TIMEZONES_SHORT, [ TIMEZONES_LABEL.moscow, TIMEZONES_LABEL.novosibirsk ]))}
        </Select.OptGroup>
      </Select>
    );
  };

  const renderCitySelect = () => {
    if (!isEdit && !changedUserValues.location) {
      return (
        <Space className={s.notSpecified} align="center">
          <FontAwesomeIcon icon="exclamation-circle" style={{ fontSize: 20 }} />
          Not specified
        </Space>
      );
    }

    const handleOnFocus = () => {
      if (changedUserValues.location) {
        getLocationBySearch(changedUserValues.location);
      }
    };

    const cityOptions = _.map(locationList, (l) => {
      const value = `${l?.data?.country || 'Unknown country'}, ${l?.data?.city || 'Unknown city'}`;

      return (
        <Select.Option
          value={value}
          key={l?.data?.geonameId}
        >
          {value}
        </Select.Option>
      );
    });

    return (
      <Select
        placeholder={isEdit ? 'Type city...' : 'Not specified'}
        className={cx(selects.qsSelect, s.profileSelect)}
        dropdownClassName={selects.selectDropdown}
        bordered={false}
        size="large"
        onChange={value => handleChangeUserValues(value, 'location')}
        onSearch={onSearchCity}
        filterOption={() => true}
        showSearch
        onFocus={handleOnFocus}
        value={changedUserValues.location}
        showArrow={false}
        disabled={!isEdit}
        notFoundContent="No results"
      >
        {cityOptions}
      </Select>
    );
  };

  const renderMainSkillDescription = () => {
    if (!isEdit) {
      return null;
    }

    return (
      <small className={s.mainSkillDescription}>For example: JS Jedi, Python Ninja, Pirate of the Caribbean...</small>
    );
  };

  const renderPrimaryEditInfo = () => {
    if (!isEdit || !editByAdmin) {
      return null;
    }

    const userTypesList = (changedUserValues.usertype !== USER_TYPES.BOT) ? _.omit(USER_TYPES, USER_TYPES.BOT) : USER_TYPES;

    return (
      <Space size={24} className={s.editPrimaryInfoWrapper}>
        <Space>
          <span className={s.profileLabel}>Usertype:</span>
          <Select
            className={cx(selects.qsSelect, s.roleSwitcher)}
            dropdownClassName={selects.selectDropdownWide}
            suffixIcon={<SelectIcon />}
            onChange={value => handleChangeUserValues(value, 'usertype')}
            size="large"
            value={changedUserValues.usertype}
            disabled={changedUserValues.usertype === USER_TYPES.BOT}
            bordered={false}
          >
            { _.map(userTypesList, (t, key) => <Select.Option key={t} value={t} className={s.roleSwitcherItem}>{_.toLower(key)}</Select.Option>) }
          </Select>
        </Space>

        <Space
          className={s.permissionsSelectWrapper}
          hidden={user.usertype === USER_TYPES.ADMIN || user.usertype === USER_TYPES.BOT}
        >
          <Popover
            placement="bottomLeft"
            trigger="click"
            className={s.permissionsSelect}
            align={{ offset: [ 0, -6 ] }}
            overlayClassName={s.permissionsSelectOverlay}
            content={_.map(EXTRA_PERMISSIONS, (permissionName, permissionValue) => (
              <Checkbox
                key={permissionName}
                checked={changedUserValues[permissionValue as keyof InitialUserValues] as boolean}
                onChange={event => handleChangeUserValues(event.target.checked, permissionValue as keyof InitialUserValues)}
              >
                {permissionName}
              </Checkbox>
            ))}
          >
            <div>
              Extra permissions
              {extraPermissionsAmount > 0 && (
                <span className={cx(s.permissionCounter, 'permissionCounter')}>{extraPermissionsAmount}</span>
              )}
              <SelectIcon />
            </div>
          </Popover>
        </Space>

        <Checkbox
          checked={changedUserValues.active}
          onChange={event => handleChangeUserValues(event.target.checked, 'active')}
        >
          Active user
        </Checkbox>
      </Space>
    );
  };

  const profileEditButton = !isReadOnlyMode && (
    <Button
      className={cx(buttons.qsButton, s.editProfileButton)}
      onClick={handleOnEditSwitch}
      icon={<EditIcon />}
    >
      Edit profile
    </Button>
  );

  const profileEditButtonGroup = (
    <div className={s.profileButtonGroup}>
      <Button
        className={buttons.qsButton}
        onClick={handleOnCancel}
      >
        Cancel
      </Button>

      <Button
        className={buttons.qsButtonPrimary}
        onClick={handleOnSave}
        type="primary"
        loading={saveLoading}
        icon={<FontAwesomeIcon icon="check-circle" />}
        disabled={(!editByAdmin && (
          _.isEmpty(changedUserValues.occupation)
           || _.isEmpty(changedUserValues.forename)
           || _.isEmpty(changedUserValues.surname)
           || _.isEmpty(changedUserValues.skypeId)
           || _.isEmpty(changedUserValues.occupation)
           || _.isEmpty(changedUserValues.location)
           || !_.isNumber(changedUserValues.timezoneUtcOffset)))
          || errorHelpers.isWithErrors()}
      >
        Save
      </Button>
    </div>
  );

  const handleOnChangeEntities = (entities: any) => {
    const newEntities = _.map(entities, entityObj => ({
      name: entityObj.label,
      entityId: entityObj.value,
    }));

    handleChangeUserValues([ ...newEntities ], 'entities');
  };

  const renderEntities = () => {
    if (!editByAdmin || !isEdit) {
      return (
        <div className={s.entityField}>
          {
            _.map(changedUserValues.entities, 'name').join(', ')
          }
        </div>
      );
    }

    const options = _.map(paginatedEntities?.objects, e => (
      <Select.Option
        value={e.entityId}
        label={e.name}
        key={e.entityId}
      >
        {e.name}
      </Select.Option>
    ));

    return (
      <Select
        mode="multiple"
        onChange={handleOnChangeEntities}
        className={cx(selects.qsSelect, s.profileSelect, s.entitySelect)}
        dropdownClassName={selects.selectDropdown}
        onFocus={() => {
          if (_.isEmpty(paginatedEntities) && !loadingEntities) {
            getEntitiesList(INCREASED_PAGINATION_RAW);
          }
        }}
        bordered={false}
        size="large"
        showSearch
        labelInValue
        value={_.map(changedUserValues.entities, entityObj => ({
          value: entityObj.entityId,
          label: entityObj.name,
        }))}
        onSearch={setSearchEntities}
        searchValue={searchEntities}
        filterOption={() => true}
        suffixIcon={<SelectIcon />}
        autoClearSearchValue={false}
        disabled={!editByAdmin || !isEdit}
        loading={loadingEntities}
        dropdownRender={menu => (
          <Spin spinning={loadingEntities} size="small">
            {menu}
          </Spin>
        )}
      >
        { options }
      </Select>
    );
  };

  return (
    <div className={s.profile}>
      <header className={s.profileHeader}>
        <h1>{editByAdmin || curatorOnlyView ? `${user.forename}'s profile` : 'My profile'}</h1>
        <Space size={20} align="center">
          { renderPrimaryEditInfo() }

          { !isEdit ? <span className={s.profileUsername}>{`Username: ${user.username}`}</span> : null }

          { isEdit ? profileEditButtonGroup : profileEditButton }
        </Space>
      </header>

      <div className={s.profileBlock}>
        <h5 className={s.profileBlockHeader}>Personal info</h5>
        <div className={cx(s.profileBlockWrapper, { [s.large]: true })}>
          <Row gutter={[ 11, 12 ]} className={s.profileBlockRow}>
            <Col span={24} xl={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={cx(s.profileLabel, { [s.required]: isEdit })}>First Name</span>
                {renderFieldsWithErrors('forename', true, { min: 2, max: 63 })}
              </Space>
            </Col>
            <Col span={24} xl={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={cx(s.profileLabel, { [s.required]: isEdit })}>Last Name</span>
                {renderFieldsWithErrors('surname', true, { min: 2, max: 63 })}
              </Space>
            </Col>
          </Row>
          <Row className={s.profileBlockRow}>
            <Col span={24}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={s.profileLabel}>About me</span>
                {renderTextAreaFieldWithErrors('description')}
              </Space>
            </Col>
          </Row>
        </div>
      </div>

      <div className={s.profileBlock}>
        <h5 className={s.profileBlockHeader}>Contacts</h5>
        <div className={cx(s.profileBlockWrapper, { [s.large]: true })}>
          <Row gutter={[ 11, 10 ]} className={s.profileBlockRow}>
            <Col span={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={cx(s.profileLabel, { [s.information]: true })}>
                  <span>Skype</span>
                  {
                    isEdit && (
                      <Popover
                        content="Skype profile > Skype Name"
                      >
                        <FontAwesomeIcon icon="question-circle" className={s.questionMarkInfo} />
                      </Popover>
                    )
                  }
                </span>
                {renderFieldsWithErrors('skypeId')}
              </Space>
            </Col>

            <Col span={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={s.profileLabel}>Telegram</span>
                {renderFieldsWithErrors('telegramUsername')}
              </Space>
            </Col>

            <Col span={24} xl={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={s.profileLabel}>Mobile</span>
                <Input
                  type="tel"
                  onChange={handleFieldsChange}
                  name="phone"
                  placeholder={(changedUserValues.phone || isEdit) ? 'Mobile' : '—'}
                  className={cx(inputs.qsInput, s.profileInput)}
                  value={changedUserValues.phone}
                  disabled={!isEdit}
                />
              </Space>
            </Col>

            <Col span={24} xl={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={s.profileLabel}>Slack</span>
                <Input
                  onChange={handleFieldsChange}
                  name="slackMemberId"
                  placeholder={(changedUserValues.slackMemberId || isEdit) ? 'Slack' : '—'}
                  className={cx(inputs.qsInput, s.profileInput, { [s.isEdit]: isEdit, [inputs.error]: errorHelpers.isErrorField('slackMemberId') })}
                  value={changedUserValues.slackMemberId}
                  disabled={!editByAdmin || !isEdit}
                />
                { errorHelpers.renderError('slackMemberId') }
              </Space>
            </Col>

            <Col span={24} xl={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={s.profileLabel}>Email</span>
                <Input
                  type="email"
                  onChange={handleFieldsChange}
                  name="emailAddress"
                  placeholder={(changedUserValues.emailAddress || isEdit) ? 'Email address' : '—'}
                  className={cx(inputs.qsInput, s.profileInput, { [s.isEdit]: isEdit, [inputs.error]: errorHelpers.isErrorField('emailAddress') })}
                  value={changedUserValues.emailAddress}
                  disabled={!editByAdmin || !isEdit}
                />

                { errorHelpers.renderError('emailAddress') }
              </Space>
            </Col>

            <Col span={24} xl={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={s.profileLabel}>Secondary Email</span>
                <Input
                  type="email"
                  onChange={handleFieldsChange}
                  name="secondaryEmailAddress"
                  placeholder={(changedUserValues.secondaryEmailAddress || (isEdit && editByAdmin)) ? 'Secondary email address' : '—'}
                  className={cx(inputs.qsInput, s.profileInput, { [s.isEdit]: isEdit, [inputs.error]: errorHelpers.isErrorField('emailAddress') })}
                  value={changedUserValues.secondaryEmailAddress}
                  disabled={!editByAdmin || !isEdit}
                />

                { errorHelpers.renderError('secondaryEmailAddress') }
              </Space>
            </Col>
          </Row>

          <div className={s.divider} />

          <Row gutter={[ 11, 12 ]} className={s.profileBlockRow}>
            <Col span={24} xl={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={cx(s.profileLabel, { [s.required]: isEdit })}>Current location</span>
                {renderCitySelect()}
              </Space>
            </Col>
            <Col span={24} xl={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={cx(s.profileLabel, { [s.required]: isEdit })}>Timezone</span>
                {renderTimezoneSelect()}
              </Space>
            </Col>
          </Row>
        </div>
      </div>

      <div className={s.profileBlock}>
        <h5 className={s.profileBlockHeader}>Professional info</h5>
        <div className={cx(s.profileBlockWrapper, { [s.small]: true })}>
          <Row gutter={[ 11, 12 ]} className={s.profileBlockRow}>
            <Col span={24} xl={12}>
              { renderOccupations() }
            </Col>
            <Col span={24} xl={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={s.profileLabel}>Entities</span>
                { renderEntities() }
              </Space>
            </Col>
          </Row>
        </div>

      </div>

      <div className={s.profileBlock}>
        <h5 className={s.profileBlockHeader}>&nbsp;</h5>
        <div className={cx(s.profileBlockWrapper, { [s.small]: true })}>
          <Row gutter={[ 11, 12 ]} className={s.profileBlockRow}>
            <Col span={24} xl={12}>
              { renderMainSkill() }
            </Col>
            <Col span={24} xl={12}>
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <span className={s.profileLabel}>Custom title</span>
                <Input
                  placeholder={isEdit ? 'Enter your custom title' : 'Not specified'}
                  onChange={handleFieldsChange}
                  name="title"
                  maxLength={31}
                  className={cx(inputs.qsInput, s.profileInput, { [s.isEdit]: isEdit })}
                  value={changedUserValues?.title}
                  disabled={!isEdit}
                />
              </Space>
            </Col>
          </Row>
          { renderMainSkillDescription() }
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
