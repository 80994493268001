import dayjs from 'dayjs';
import _ from 'lodash';

import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';
import { PARTICIPANTS_TABS } from 'api/User/constants';
import { GetPartnersUsersBody } from 'components/PartnersUsers/types';

import { GetPartnerParticipantsBody, PartnersRequestBody } from './types';

const ONLY_REAL_VALUES_LIST = [ 'nameContains' ]; // if this value is false -- it won't be sent to server

// eslint-disable-next-line import/prefer-default-export
export const convertUserStatisticsBody = (body: PartnersRequestBody) => _.reduce(body, (acc, value, key) => {
  if (key === 'createdAt') {
    if (!value) {
      return acc;
    }

    const splitByTo = _.isString(value) ? _.split(value, 'to') : [];

    return {
      ...acc,
      creation_date_lte: splitByTo[0] ? dayjs().subtract(Number(splitByTo[0]), 'd').format(DEFAULT_DATE_FORMAT) : undefined,
      creation_date_gte: splitByTo[1] ? dayjs().subtract(Number(splitByTo[1]), 'd').format(DEFAULT_DATE_FORMAT) : undefined,
    };
  }

  if (key === 'orderBy' && value === 'partner_id') {
    return {
      ...acc,
      order_by: 'id',
    };
  }

  if ((_.isString(value) || _.isArray(value)) && _.isEmpty(value)) {
    return acc;
  }
  
  if (ONLY_REAL_VALUES_LIST.includes(key)) {
    return {
      ...acc,
      [_.snakeCase(key)]: value || null,
    };
  }

  return {
    ...acc,
    [_.snakeCase(key)]: value,
  };
}, {});

export const convertPartnerUserBody = (body: GetPartnersUsersBody) => _.reduce(body, (acc, value, key) => {
  if (key === 'active') {
    if (value === 'CURATOR') {
      return {
        ...acc,
        role: 'CURATOR',
      };
    } else {
      return acc;
    }
  }

  if (key === 'nameContains') {
    return {
      ...acc,
      fullname_contains: value,
      username_contains: value,
      email_address_contains: value,
      partner_name_contains: value,
    };
  }

  if (_.includes([ 'partnerActive', 'userActive' ], key) && !value) {
    return acc;
  }

  if (key === 'orderBy') {
    return {
      ...acc,
      order_by: _.snakeCase(value as string),
    };
  }

  if ((_.isString(value) || _.isArray(value)) && _.isEmpty(value)) {
    return acc;
  }
  
  if (ONLY_REAL_VALUES_LIST.includes(key)) {
    return {
      ...acc,
      [_.snakeCase(key)]: value || null,
    };
  }

  return {
    ...acc,
    [_.snakeCase(key)]: value,
  };
}, {});

export const convertPartnerParticipantsBody = (body: GetPartnerParticipantsBody) => _.reduce(body, (acc, value, key) => {
  if (key === 'activeTab') {
    // tabs changing cases over here
    switch (value) {
      case PARTICIPANTS_TABS.ALL_MEMBERS.radioValue:
        return acc;
      case PARTICIPANTS_TABS.CURATORS.radioValue:
        return {
          ...acc,
          role: 'CURATOR',
        };
    }
  }

  if ((_.isString(value) || _.isArray(value)) && _.isEmpty(value)) {
    return acc;
  }
  
  if (ONLY_REAL_VALUES_LIST.includes(key)) {
    return {
      ...acc,
      [_.snakeCase(key)]: value || null,
    };
  }

  return {
    ...acc,
    [_.snakeCase(key)]: value,
  };
}, {});
