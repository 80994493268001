/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, MouseEvent, useMemo } from 'react';
import classNames from 'classnames';
import { Button, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined } from '@ant-design/icons';

import { ActivityCardOutput, PartnerDetailsOutput } from 'api/Partners/types';
import PartnerMask from 'components/_shared/PartnerMask';
import popover from 'helpers/styles/components/popover.module.sass';
import { updateActivity } from 'api/Partners/requests';

import s from './styles.module.sass';

interface ActivityProps {
  activity: ActivityCardOutput;
  partner: PartnerDetailsOutput;
  openActivityDrawer: (activity: ActivityCardOutput) => void;
  updateActivities: (activities: ActivityCardOutput) => void;
  editActivity: (activity: ActivityCardOutput) => void;
}

const Activity: React.FC<ActivityProps> = ({ activity, partner, editActivity, openActivityDrawer, updateActivities }) => {
  const [ isLoading, setLoading ] = useState(false);
  const [ isPopoverOpen, setPopoverOpen ] = useState(false);

  const activeStatusStr = useMemo(() => (activity.active ? 'Deactivate' : 'Activate'), [ activity.active ]);

  const activeStatusToggle = (e: MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    
    const updatedActivity = {
      ...activity, 
      active: !activity.active,
    };

    setLoading(true);
    updateActivity(partner.partnerId, updatedActivity.activityId, { active: updatedActivity.active })
      .then(() => updateActivities(updatedActivity))
      .finally(() => {
        setLoading(false);
        setPopoverOpen(false);
      });
  };

  const renderButtonInner = () => (
    isLoading
      ? (
        <LoadingOutlined style={{ fontSize: 10 }} className={s.spinner} spin />
      )
      : activeStatusStr
  );

  const renderPopoverContent = () => (
    <div className={popover.dotsPopoverItemWrapper}>
      <Button
        className={classNames(popover.dotsPopoverItem, s.popoverButton)}
        onClick={(e: MouseEvent<HTMLInputElement>) => {
          e.stopPropagation();

          editActivity(activity);
          setPopoverOpen(false);
        }}
        disabled={isLoading}
      >
        Edit
      </Button>
      <Button
        className={classNames(popover.dotsPopoverItem, s.popoverButton)}
        onClick={activeStatusToggle}
        disabled={activity.hasHours || isLoading}
      >
        {renderButtonInner()}
      </Button>
    </div>
  );
  
  return (
    <div
      className={classNames(s.activity, { [s.inactive]: !activity.active })}
      onClick={() => openActivityDrawer(activity)}
    >
      <div className={s.border} style={{ background: `${activity.color}` }} />
      <PartnerMask
        iconColor={activity.color}
        wrapperColor={partner.color}
        mask={partner.mask}
        iconClassName={s.maskIconClassName}
        wrapperClassName={s.maskWrapperClassName}
        partnerId={partner.partnerId}
      />
      <span className={s.activityName}>{activity.name}</span>
      <span className={s.activityId}>{`ID: ${activity.activityId}`}</span>
      <div className={s.verticalSeparator} />
      <div
        className={s.activityColorMark}
        style={{ backgroundColor: activity.color }}
      />
      <Popover
        overlayClassName={popover.dotsPopoverOverlay}
        placement="left"
        trigger="click"
        visible={isPopoverOpen}
        content={renderPopoverContent()}
        onVisibleChange={(status: boolean) => setPopoverOpen(status)}
      >
        <Button
          type="link"
          className={s.threeDotsIcon}
          onClick={(e: MouseEvent<HTMLInputElement>) => {
            e.stopPropagation();

            setPopoverOpen(true);
          }}
        >
          <FontAwesomeIcon className={s.threeDotsIcon} icon="ellipsis-v" />
        </Button>
      </Popover>
    </div>
  );
};

export default Activity;
