import React from 'react';
import cx from 'classnames/bind';
import { Button, Col, Row } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

import FieldCard from 'components/_shared/FieldCard/FieldCard';
import { PartnerDetailsOutput, PartnerMaskType } from 'api/Partners/types';
import PartnerMask from 'components/_shared/PartnerMask';
import { ReactComponent as ArrowIcon } from 'helpers/icons/arrowIcon.svg';
import PartnerMaskPicker from 'components/_shared/PartnerMaskPicker/PartnerMaskPicker';
import { ReactComponent as RandomizeIcon } from 'helpers/icons/RandomizeIcon.svg';
import { NEW_PARTNER_MASKS } from 'helpers/constants/_common/Partners';
import COLORS from 'helpers/constants/_common/colors';
import ColorPicker from 'components/_shared/ColorPicker/ColorPicker';

import s from './styles.module.sass';
import partners from '../../styles.module.sass';

interface AppearanceCardProps {
  changeField: (key: keyof PartnerDetailsOutput, value: any) => void;
  partnerFields: PartnerDetailsOutput;
  isEdit: boolean;
}

const AppearanceCard: React.FC<AppearanceCardProps> = ({ partnerFields, isEdit, changeField }) => {
  const renderPartnerMaskInput = () => (isEdit ? (
    <PartnerMaskPicker
      partnerColor={partnerFields.color}
      maskColor={partnerFields.maskColor}
      onChange={(mask: PartnerMaskType) => changeField('mask', mask)}
      value={partnerFields.mask}
    />
  ) : (
    <Button
      className={classNames(s.partnerMaskPicker, { [s.editable]: isEdit })}
      style={{ background: partnerFields.color }}
      disabled={!isEdit}
    >
      <PartnerMask
        mask={partnerFields.mask}
        iconColor={partnerFields.maskColor}
        iconClassName={s.partnerMask}
        wrapperColor={partnerFields.color}
        wrapperClassName={s.partnerMaskWrapper}
        partnerId={partnerFields.partnerId}
      />
      <ArrowIcon className={s.arrowIcon} />
    </Button>
  ));

  const setRandomMask = () => {
    const mask = (_.sample(NEW_PARTNER_MASKS)?.key as PartnerMaskType) || 'DEFAULT';
    
    if (mask === partnerFields.mask) {
      setRandomMask();
    } else {
      changeField('mask', mask);
    }
  };

  const setRandomColor = () => {
    const color = _.sample(COLORS) || COLORS.Blue;
    
    if (color === partnerFields.color) {
      setRandomColor();
    } else {
      changeField('color', color);
    }
  };

  const renderPartnerColorInput = () => (isEdit ? (
    <ColorPicker
      color={partnerFields.color}
      onChange={(color: string) => changeField('color', color)}
    >
      <div
        className={s.colorPicker}
        style={{ backgroundColor: partnerFields.color }}
      />
    </ColorPicker>
  ) : (
    <div
      className={cx(s.primaryColorPicker, { [s.disabled]: !isEdit })}
      style={{ background: partnerFields.color }}
    />
  ));

  return (
    <FieldCard
      title="Appearance"
      wrapperClassName={cx(partners.titledCardWrapper)}
      className={cx(s.appearanceCard, partners.titledCard)}
    >
      <Row>
        <Col span={12}>
          <h4 className={s.fieldTitle}>
            <span>Mask</span>
            {isEdit && (
              <Button
                className={s.randomizeButton}
                onClick={setRandomMask}
                style={{ marginRight: '15px' }}
              >
                Randomize
                <RandomizeIcon />
              </Button>
            )}
          </h4>
          <div className={s.fieldInner}>
            {renderPartnerMaskInput()}
            {isEdit && (
              <span className={s.fieldDescription}>
                Select a mask
                <br />
                for the partner
              </span>
            )}
          </div>
        </Col>
        <Col span={12} className={s.withVerticalSeparator}>
          <h4 className={s.fieldTitle}>
            <span>Primary color</span>
            {isEdit && (
              <Button
                className={s.randomizeButton}
                onClick={setRandomColor}
              >
                Randomize
                <RandomizeIcon />
              </Button>
            )}
          </h4>
          <div className={s.fieldInner}>
            {renderPartnerColorInput()}
            {isEdit && (
              <span className={s.fieldDescription}>
                Pick the primary
                <br />
                color of the partner
              </span>
            )}
          </div>
        </Col>
      </Row>
    </FieldCard>
  );
};

export default AppearanceCard;
