import { Button, Space, Switch, Typography } from 'antd';
import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import buttons from 'helpers/styles/components/buttons.module.sass';
import TrackerLogo from 'components/_shared/TrackerLogo/TrackerLogo';
import { NOTIFY_TO_CLOSE_MONTH, NOTIFY_TO_REPORT_HOURS, NOTIFY_VIA_EMAIL, NOTIFY_VIA_SLACK } from 'helpers/constants/_common/constants';
import switches from 'helpers/styles/components/switches.module.sass';
import { NotificationUserPreferences } from 'redux/User/types';
import { DispatchType, Redux } from 'helpers/types/_common';
import { PATH_TRACKER } from 'helpers/constants/_common/MenuCases';
import { saveUserPreferencesAction } from 'redux/PersonalInfo/action';

import s from './helpers/styles.module.sass';

interface WelcomeNotificationsPageProps {
}

const WelcomeNotificationsPage: React.FC<WelcomeNotificationsPageProps> = () => {
  const userData = useSelector((state: Redux) => state.login);
  const history = useHistory();
  
  const [ loading, setLoading ] = useState(false);
  const [ preferences, setPreferences ] = useState<NotificationUserPreferences>({
    notifyViaSlack: true,
    notifyViaEmail: true,
    notifyToCloseMonth: true,
    notifyToReportHours: true,
  });
  
  const dispatch: DispatchType = useDispatch();

  const onConfirm = () => {
    setLoading(true);

    dispatch(saveUserPreferencesAction(preferences, userData.userId))
      .then(() => {
        history.push(`${PATH_TRACKER}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSwitchChange = (field: string, value: boolean) => {
    setPreferences({ ...preferences, [field]: value });
  };

  return (
    <div className={s.notificationsWrapper}>
      <TrackerLogo className={s.trackerLogo} />
      <div className={s.bodyWrapper}>
        <Space direction="vertical" className={s.textWrapper}>
          <h1 className={s.locationTitle}>Welcome to Time Tracker</h1>
          <p className={s.primaryText}>
            Please confirm your notifications preferences to continue
          </p>
        </Space>
  
        <div className={s.splitter} />
  
        <div className={s.verticalSpace}>
          <Space direction="vertical" size={20} className={s.switchers}>
            <Space>
              <Switch
                checked={Boolean(preferences.notifyViaSlack)}
                className={switches.trackerSwitch}
                defaultChecked={Boolean(preferences.notifyViaSlack)}
                onChange={checked => onSwitchChange(NOTIFY_VIA_SLACK, checked)}
              />
              <Typography.Text>Notify me via Slack</Typography.Text>
            </Space>
    
            <Space>
              <Switch
                checked={Boolean(preferences.notifyViaEmail)}
                className={switches.trackerSwitch}
                defaultChecked={Boolean(preferences.notifyViaEmail)}
                onChange={checked => onSwitchChange(NOTIFY_VIA_EMAIL, checked)}
              />
              <Typography.Text>Notify me via e-mail</Typography.Text>
            </Space>
    
            <Space>
              <Switch
                checked={Boolean(preferences.notifyToCloseMonth)}
                className={switches.trackerSwitch}
                defaultChecked={Boolean(preferences.notifyToCloseMonth)}
                onChange={checked => onSwitchChange(NOTIFY_TO_CLOSE_MONTH, checked)}
              />
              <Typography.Text>Notify me about necessity to close month</Typography.Text>
            </Space>
    
            <Space>
              <Switch
                checked={Boolean(preferences.notifyToReportHours)}
                className={switches.trackerSwitch}
                defaultChecked={Boolean(preferences.notifyToReportHours)}
                onChange={checked => onSwitchChange(NOTIFY_TO_REPORT_HOURS, checked)}
              />
              <Typography.Text>Notify me about necessity to report my hours</Typography.Text>
            </Space>
          </Space>
    
          <div className={s.buttonGroupWrapper}>
            <Button
              type="primary"
              size="large"
              loading={loading}
              className={cx(buttons.qsButtonPrimary, s.allowButton)}
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeNotificationsPage;
