import { ReactComponent as Default } from 'helpers/icons/Masks/default-new.svg';
import { ReactComponent as Round } from 'helpers/icons/Masks/round.svg';
import { ReactComponent as Diagonals } from 'helpers/icons/Masks/diagonals.svg';
import { ReactComponent as CircleDot } from 'helpers/icons/Masks/circle-dot.svg';
import { ReactComponent as Triquestre } from 'helpers/icons/Masks/triquestre.svg';
import { ReactComponent as CircleRound } from 'helpers/icons/Masks/circle-round.svg';
import { ReactComponent as Star } from 'helpers/icons/Masks/star.svg';
import { ReactComponent as Qs } from 'helpers/icons/Masks/qs.svg';
import { ReactComponent as Vacation } from 'helpers/icons/Masks/vacation.svg';

export const PARTNER_MASKS = {
  DEFAULT: { icon: Default, title: 'Square', key: 'DEFAULT' },
  ROUND: { icon: Round, title: 'Round', key: 'ROUND' },
  DIAGONALS: { icon: Diagonals, title: 'Diagonals', key: 'DIAGONALS' },
  CIRCLE_ROUND: { icon: CircleRound, title: 'Circle-round', key: 'CIRCLE_ROUND' },
  CIRCLED_DOT: { icon: CircleDot, title: 'Circled dot', key: 'CIRCLED_DOT' },
  STAR: { icon: Star, title: 'Star', key: 'STAR' },
  TRIQUESTRE: { icon: Triquestre, title: 'Triquestre', key: 'TRIQUESTRE' },
  QS: { icon: Qs, title: 'Quantum Soft', key: 'QS' },
  VACATION: { icon: Vacation, title: 'Vacation', key: 'VACATION' },
};

export const NEW_PARTNER_MASKS = {
  DEFAULT: { icon: Default, title: 'Square', key: 'DEFAULT' },
  ROUND: { icon: Round, title: 'Round', key: 'ROUND' },
  DIAGONALS: { icon: Diagonals, title: 'Diagonals', key: 'DIAGONALS' },
  CIRCLE_ROUND: { icon: CircleRound, title: 'Circle-round', key: 'CIRCLE_ROUND' },
  CIRCLED_DOT: { icon: CircleDot, title: 'Circled dot', key: 'CIRCLED_DOT' },
  STAR: { icon: Star, title: 'Star', key: 'STAR' },
  TRIQUESTRE: { icon: Triquestre, title: 'Triquestre', key: 'TRIQUESTRE' },
};

export const SPECIFIC_PARTNERS_IDS = {
  107: PARTNER_MASKS.QS,
  105: PARTNER_MASKS.VACATION,
};

export const SPECIFIC_PARTNERS_IDS_NAMES = {
  QS: 107,
  VACATION: 105,
};

export const PARTNER_NAME_MIN_LENGTH = 2;
