import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Key } from 'antd/lib/table/interface';
import _ from 'lodash';
import classNames from 'classnames';

import { TABLE_NAMES } from 'helpers/constants/Tables/tableList';
import BasicTable from 'components/_shared/Table';
import { Redux } from 'helpers/types/_common';
import { Meta, Sorter } from 'api/_request/types';
import PartnerMask from 'components/_shared/PartnerMask';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import Deactivated from 'components/_shared/Deactivated/Deactivated';

import { PartnersUserOutput, PartnersUsersFiltersState, PartnerUserOutputChecked } from '../types';
import PARTNERS_USERS_COLUMNS from './columns';
import s from './styles.module.sass';

interface PartnersUsersTableProps {
  getPartnersData: (pagination: Meta, sorter?: Sorter) => void;
  setSelectedRows: (selectedRows: PartnerUserOutputChecked[]) => void;
  filters: PartnersUsersFiltersState;
  selectedRows: PartnerUserOutputChecked[];
}

const PartnersUsersTable: React.FC<PartnersUsersTableProps> = ({ getPartnersData, filters, selectedRows, setSelectedRows }) => {
  const { meta, objects, loading, sorter } = useSelector((state: Redux) => state.tables[TABLE_NAMES.PARTNERS_USERS]);

  useEffect(() => {
    refreshTable();
  }, [ filters ]);

  const refreshTable = () => {
    getPartnersData(meta, sorter);
  };

  const onChangeRowSelection = (selectedRowKeys: Key[], selectedRowsOnChange: any[]) => {
    const markedCheckedSelectedRows = _.map(selectedRowsOnChange, s => ({ ...s, checked: true }));

    setSelectedRows(markedCheckedSelectedRows);
  };

  const rowSelection = {
    onChange: onChangeRowSelection,
    preserveSelectedRowKeys: true,
    selectedRowKeys: _.map(selectedRows, row => row.partnerId),
  };

  const renderColumns = {
    partnerId: {
      render: (text: string, record: PartnersUserOutput) => record.partner.partnerId,
    },
    userId: {
      render: (text: string, record: PartnersUserOutput) => record.user.userId,
    },
    partnerName: {
      render: (text: string, record: PartnersUserOutput) => (
        <div className={classNames(s.partner, s.flexRow)} key={record.partnerUserId}>
          <PartnerMask
            mask={record.partner.mask}
            iconColor={record.partner.maskColor}
            wrapperColor={record.partner.color}
            wrapperClassName={s.maskWrapper}
            partnerId={record.partner.partnerId}
          />
          <span className={s.name}>{record.partner.name}</span>
          <Deactivated isHidden={record.partner.active} />
        </div>
      ),
    },
    userFullname: {
      render: (text: string, record: PartnersUserOutput) => (
        <div className={classNames(s.user, s.flexRow)} key={record.partnerUserId}>
          <UserAvatar
            avatarUrl={record.user.avatarUrl}
            size={24}
          />
          <span className={s.name}>{`${record.user.forename} ${record.user.surname}`}</span>
          <Deactivated isHidden={record.user.active} />
        </div>
      ),
    },
    roles: {
      render: (text: string, record: PartnersUserOutput) => _.join(_.map(record.roles, role => _.capitalize(role)), ', '),
    },
  };

  return (
    <div>
      <BasicTable
        rowKey="partnerId"
        className={s.employeesTable}
        tableName={TABLE_NAMES.PARTNERS_USERS}
        getData={getPartnersData}
        initialRequest={false}
        columns={PARTNERS_USERS_COLUMNS}
        renderColumns={renderColumns}
        dataSource={objects}
        pagination={meta}
        loading={loading}
        bordered
      />
    </div>
  );
};

export default PartnersUsersTable;
