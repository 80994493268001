/* eslint-disable import/prefer-default-export */
import { NotificationTypes } from '../../../../../api/Notifications/types';

export const NOTIFICATION_MODAL_OPTIONS = {
  update: {
    value: NotificationTypes.updateSkillset,
    label: 'Update skillset',
  },
  closeMonth: {
    value: NotificationTypes.closeMonth,
    label: 'Close month',
  },
};
