import React, { useState } from 'react';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import popover from 'helpers/styles/components/popover.module.sass';
import { BaseActivityOutput, UserPartnerOutput, UserPinnedActivityOutput } from 'api/Partners/types';
import PartnerMask from 'components/_shared/PartnerMask';
import SpinnerNew from 'components/_shared/LoadingSpinnerNew';
import { DispatchType, Redux } from 'helpers/types/_common';

import s from './styles.module.sass';
import { setPinnedActivity } from '../../../../../../../api/Partners/requests';
import cx from 'classnames';

interface ActivityListItemProps {
  partnerObject: UserPartnerOutput;
  onAddActivity: (activityId: number) => Promise<any> | undefined;
  setPopoverVisible: (visible: boolean) => void;
  activity: BaseActivityOutput;
  curatorOnlyView?: boolean;
}

const PartnerActivityListItem: React.FC<ActivityListItemProps> = ({ partnerObject, onAddActivity, setPopoverVisible, activity, curatorOnlyView }) => {
  const dispatch: DispatchType = useDispatch();
  const { isMonthClosed } = useSelector((state: Redux) => state.timeSheet.tableSettings);
  const [ activityLoading, setActivityLoading ] = useState(false);

  const handleOnAddActivity = (activity: BaseActivityOutput) => {
    if (curatorOnlyView) {
      return;
    }

    setActivityLoading(true);

    return onAddActivity(activity.activityId)
      ?.then((pinnedActivity: UserPinnedActivityOutput) => {
        if (!isMonthClosed) dispatch(setPinnedActivity(pinnedActivity));
        setPopoverVisible(false);
      })
      ?.finally(() => setActivityLoading(false));
  };

  return (
    <Space
      align="center"
      onClick={() => handleOnAddActivity(activity)}
      className={cx(popover.dotsPopoverItem, { [s.disabledPartnerItem]: curatorOnlyView })}
      key={activity.activityId}
    >
      <PartnerMask
        wrapperColor={partnerObject.partner.color}
        iconColor={activity.color}
        partnerId={partnerObject.partner.partnerId}
        mask={partnerObject.partner.mask}
      />
      <span className={popover.dotsPopoverItemText} title={activity.name}>{activity.name}</span>
      { activityLoading ? <SpinnerNew className={s.partnerLoadingIcon} small /> : null }
    </Space>
  );
};

export default PartnerActivityListItem;
