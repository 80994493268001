export const SLACK_GROUP_ID = 'T0GJ43PGR';

export const USER_TYPES_ID = {
  ADMIN: 1,
  USER: 2,
  BOT: 3,
};

export const USER_TYPES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  BOT: 'BOT',
};

export const EXTRA_PERMISSIONS = {
  hasSpecialistProfilesReadAccess: 'Search specialists & View skillsets',
};

export const QS_ENTITIES = {
  1: {
    label: 'QS Group LLC',
    value: 1,
  },
  2: {
    label: 'KM Software SIA',
    value: 2,
  },
  3: {
    label: 'QuantumSoft Inc',
    value: 3,
  },
};

export enum Themes {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum UiDrawMode {
  MOUSE = 'MOUSE_ONLY',
  MOUSE_AND_CTRL = 'MOUSE_AND_CTRL',
}

export const ASK_CONFIRMATION_ON_DELETE_HOURS = 'askConfirmationOnDeleteHours';
export const ASK_CONFIRMATION_ON_OVERWRITE_HOURS = 'askConfirmationOnOverwriteHours';
export const NOTIFY_VIA_SLACK = 'notifyViaSlack';
export const NOTIFY_VIA_EMAIL = 'notifyViaEmail';
export const NOTIFY_TO_CLOSE_MONTH = 'notifyToCloseMonth';
export const NOTIFY_TO_REPORT_HOURS = 'notifyToReportHours';
