import React from 'react';
import cx from 'classnames';
import { Space, Tooltip } from 'antd';

import popover from 'helpers/styles/components/popover.module.sass';
import PartnerMask from 'components/_shared/PartnerMask';
import { UsersHourResponse } from 'api/TimeSheet/types';
import { ReactComponent as ReplaceIcon } from 'helpers/icons/swapCircle24.svg';
import tooltips from 'helpers/styles/components/tooltips.module.sass';
import { SalaryObject } from 'components/_shared/SalaryField/types';

import s from './styles.module.sass';
import { MonthInfoModalState } from '../../helpers/types';

interface MonthInfoPopoverItemProps {
  partner: UsersHourResponse;
  setModalInfo: React.Dispatch<React.SetStateAction<MonthInfoModalState>>;
  salaryObject: SalaryObject;
  isMonthClosed: boolean;
  curatorOnlyView?: boolean;
}

const MonthInfoPopoverItem: React.FC<MonthInfoPopoverItemProps> = ({ partner, setModalInfo, salaryObject, isMonthClosed, curatorOnlyView }) => {
  const handleChangeActivity = () => {
    setModalInfo({
      visible: true,
      partner,
    });
  };

  const salarySum = salaryObject.hourly ? `${salaryObject.hourly * partner.hours} ₽` : '';

  return (
    <div className={cx(popover.dotsPopoverItem, s.monthPopoverItem)}>
      <Space>
        <PartnerMask
          wrapperColor={partner.partner.color}
          iconColor={partner.activity.color}
          partnerId={partner.partner.partnerId}
          mask={partner.partner.mask}
        />
        {`${partner.partner.name}: ${partner.activity.name}`}
      </Space>

      <div className={s.monthPopoverCounter}>
        <span className={s.monthPopoverSalary}>{salarySum}</span>
        <span>{`${partner.hours}h`}</span>

        {
          (!isMonthClosed && !curatorOnlyView) && (
            <Tooltip
              title="Replace activity"
              overlayClassName={tooltips.trackerTooltip}
              placement="right"
            >
              <ReplaceIcon
                className={s.monthPopoverSwapIcon}
                onClick={handleChangeActivity}
              />
            </Tooltip>
          )
        }

      </div>

    </div>
  );
};

export default MonthInfoPopoverItem;
