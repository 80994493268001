/* eslint-disable no-useless-escape */
import { Button, Input, Modal, Select, Space } from 'antd';
import React, { ChangeEvent, useEffect, useState, KeyboardEvent, useMemo } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import modals from 'helpers/styles/components/modals.module.sass';
import buttons from 'helpers/styles/components/buttons.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import { ReactComponent as RandomizeIcon } from 'helpers/icons/cached_black_24.svg';
import { NEW_PARTNER_MASKS, PARTNER_NAME_MIN_LENGTH } from 'helpers/constants/_common/Partners';
import { AddNewPartnerProps, NewActivity, PartnerMaskType } from 'api/Partners/types';
import { getAllUsers } from 'api/User/requests';
import { ReactComponent as ArrowIcon } from 'helpers/icons/arrowIcon.svg';
import { addNewPartner } from 'api/Partners/requests';
import { setError } from 'redux/ErrorHandler/actions';
import { DispatchType, ValidationErrorResponse } from 'helpers/types/_common';
import InfinityScroll from 'components/_shared/InfinityScroll';
import { BaseUserOutput } from 'api/User/types';
import COLORS from 'helpers/constants/_common/colors';
import ColorPicker from 'components/_shared/ColorPicker/ColorPicker';
import useErrorForm from 'helpers/hooks/useErrorForm/useErrorForm';

import s from './styles.module.sass';
import PartnerMask from '../../../_shared/PartnerMask';
import PartnerCustomization from './components/PartnerCustomization/PartnerCustomization';

interface NewPartnerModalProps {
  visible: boolean;
  onVisibleChange: (visible: boolean, withRefresh?: boolean) => void;
}

interface ActivityList {
  [ key: string ]: NewActivity;
}

const DEFAULT_VALUES: AddNewPartnerProps = {
  name: '',
  description: '',
  mask: 'DEFAULT' as PartnerMaskType,
  color: '#000E85',
};

const DEFAULT_ACTIVITY_COLOR = COLORS.Blue;

const DEFAULT_NEW_ACTIVITY: NewActivity = {
  name: '',
  description: '',
  color: DEFAULT_ACTIVITY_COLOR,
};

const NewPartnerModal: React.FC<NewPartnerModalProps> = ({ visible, onVisibleChange }) => {
  const [ fields, setFields ] = useState<AddNewPartnerProps>(DEFAULT_VALUES);
  const [ isLoading, setLoading ] = useState(false);
  const [ isAddingActivityInProgress, setAddingActivityInProgress ] = useState(false);
  const [ activityList, setActivityList ] = useState<ActivityList>({});
  
  const [ errorHelper, setValidationError, validationErrors ] = useErrorForm<ValidationErrorResponse>();
  
  const disabledColors = useMemo(() => [ fields.color, ..._.map(activityList, activity => activity.color) ], [ activityList, fields.color ]);
  const maskColor = _.values(activityList)[0]?.color || 'red';
  
  const dispatch: DispatchType = useDispatch();

  useEffect(() => {
    setFields(DEFAULT_VALUES);
    setAddingActivityInProgress(false);
    setActivityList({});

    if (visible) {
      setRandomPartnerColorAndMask();
    }
  }, [ visible ]);

  // handlers

  const setPartnerInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    errorHelper.doValidateByFieldChanging(name as keyof AddNewPartnerProps, value, e);
    
    setFields({
      ...fields,
      [name]: value,
    });
  };

  const setActivityName = (event: ChangeEvent<HTMLInputElement>, editedPseudoId: string) => {
    if (_.isEmpty(activityList)) return;

    const { value } = event.target;
    const updatedList = _.reduce(activityList, (result, activity, pseudoId) => {
      if (pseudoId === editedPseudoId) {
        return {
          ...result,
          [pseudoId]: {
            ...activity,
            name: value,
            description: value, // TODO: Need to write correct description here
          },
        };
      } else {
        return {
          ...result,
          [pseudoId]: activity,
        };
      }
    }, {} as ActivityList);

    setActivityList(updatedList);
  };

  const setActivityColor = (hex: string, editedPseudoId: string) => {
    const updatedList = _.reduce(activityList, (result, activity, pseudoId) => {
      if (pseudoId === editedPseudoId) {
        return {
          ...result,
          [pseudoId]: {
            ...activity,
            color: hex,
          },
        };
      } else {
        return {
          ...result,
          [pseudoId]: activity,
        };
      }
    }, {} as ActivityList);

    setActivityList(updatedList);
  };

  const addNewActivity = () => {
    const color = _.filter(COLORS, (color) => {
      if (color === fields.color) return false;
      if (_.find(activityList, activity => color === activity.color)) return false;
      return true;
    })[0];

    const getPseudoId = () => {
      if (_.isEmpty(activityList)) {
        return Math.floor(Math.random() * 16777215);
      } else {
        return +_.keys(activityList)[0] - 1;
      }
    };

    setActivityList({
      [getPseudoId()]: 
        {
          ...DEFAULT_NEW_ACTIVITY,
          color,
        },
      ...activityList,
    });
    setAddingActivityInProgress(false);
  };

  const removeActivity = (removingPseudoId: string) => {
    const updatedList = _.reduce(activityList, (result, activity, pseudoId) => {
      if (pseudoId === removingPseudoId) {
        return result;
      } else {
        return {
          ...result,
          [pseudoId]: activity,
        };
      }
    }, {});

    setActivityList(updatedList);
  };

  const enterKeyUpListener = (e: KeyboardEvent) => {
    if (e.keyCode !== 13) return;

    addNewActivity();
  };

  const setPartnerMask = (mask: PartnerMaskType) => {
    setFields({
      ...fields,
      mask,
    });
  };

  const setPartnerColor = (color: string) => {
    setFields({
      ...fields,
      color,
    });
  };

  const setRandomPartnerColorAndMask = () => {
    const mask = (_.sample(NEW_PARTNER_MASKS)?.key as PartnerMaskType) || 'DEFAULT';
    const color = _.sample(COLORS) || COLORS.Blue;

    setFields({
      ...fields,
      mask,
      color,
    });
  };

  const setRequestedBy = (requesterId: number) => {
    setFields({
      ...fields,
      requesterId,
    });
  };

  const savePartner = () => {
    const dataToRequest = {
      ...fields,
      activities: _.map(activityList, activity => activity),
    };

    if (_.find(dataToRequest.activities, activity => activity.name === '')) {
      return dispatch(setError({ error: 'Activity name is required field' }));
    }

    if (errorHelper.isWithErrors()) return;

    setLoading(true);
    addNewPartner(dataToRequest)
      .then(() => {
        onVisibleChange(false, true);
      })
      .catch((error) => {
        if (!_.isUndefined(error?.response?.data)) {
          switch (error.response.status) {
            case 400:
              setValidationError(error.response.data);
              break;
            case 409: {
              const { field } = error.response.data;

              setValidationError({ ...validationErrors, [field]: 'Duplicates are not allowed' });
              break;
            }
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // renderers

  const renderRequestedByField = () => (
    <Space direction="vertical">
      <span className={s.fieldName}>Added by Request of</span>
      <InfinityScroll
        dropdownAlign={{ offset: [ 0, 7 ] }}
        value={fields.requesterId}
        className={s.requestedBySelect}
        bordered={false}
        onSelect={setRequestedBy}
        placeholder="Added by request of..."
        filterOption={() => true}
        suffixIcon={<ArrowIcon />}
        depsToCleaning={[ visible ]}
        defaultElementRender={() => (
          <Select.Option
            name="Requested by anyone"
          >
            <b>Requested by anyone</b>
          </Select.Option>
        )}
        requestSettingsWithSearchValue={(searchValue: string) => ({
          orderBy: 'fullname',
          active: true,
          fullnameContains: searchValue,
          usernameContains: searchValue,
          emailAddressContains: searchValue,
        })}
        getter={getAllUsers}
        renderItem={(user: BaseUserOutput) => (
          <Select.Option
            key={user.userId}
            value={user.userId}
            name={user.forename}
          >
            {`${user.forename} ${user.surname}`}
          </Select.Option>
        )}
      />
    </Space>
  );

  const renderPrefixMask = () => (
    <PartnerMask
      wrapperColor={fields.color}
      mask={fields.mask}
      iconColor={maskColor}
      wrapperClassName={s.partnerNamePartnerMask}
    />
  );

  const renderNameField = () => (
    <Space direction="vertical">
      <span className={s.fieldName}>
        <span>
          Name
          <span className={s.red}> *</span>
        </span>
        {errorHelper.renderError('name')}
      </span>
      <Input
        prefix={renderPrefixMask()}
        className={cx(inputs.qsInput, s.addPartnerInput, { [inputs.withError]: errorHelper.isErrorField('name') })}
        placeholder="Enter partner name"
        name="name"
        required
        min={PARTNER_NAME_MIN_LENGTH}
        value={fields.name}
        onChange={setPartnerInputValue}
      />
    </Space>
  );

  const renderDescriptionField = () => (
    <Space direction="vertical">
      <span className={s.fieldName}>
        Description
        {errorHelper.renderError('description')}
      </span>
      <Input
        className={cx(inputs.qsInput, s.addPartnerInput, { [inputs.withError]: errorHelper.isErrorField('description') })}
        placeholder="Enter description text..."
        name="description"
        value={fields.description}
        onChange={setPartnerInputValue}
      />
    </Space>
  );

  const renderActivities = () => (
    _.map(activityList, (activity, pseudoId) => (
      <div key={pseudoId} className={s.activity}>
        <div className={s.activityBorder} style={{ background: activity.color }} />
        <ColorPicker
          color={activity.color}
          disabledColors={disabledColors}
          onChange={(hex: string) => setActivityColor(hex, pseudoId)}
        >
          <div className={s.newActivityMask}>
            <PartnerMask
              mask={fields.mask}
              iconColor={activity.color}
              wrapperColor={fields.color}
              wrapperClassName={s.partnerNamePartnerMask}
            />          
          </div>
        </ColorPicker>
        <Input
          className={cx(inputs.qsInput, s.addNewActivityInput)}
          placeholder="Enter activity name"
          value={activity.name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setActivityName(event, pseudoId)}
          onKeyUp={enterKeyUpListener}
        />
        <div className={s.activityButtonsWrapper}>
          <div className={s.activityButtons}>
            <Button
              size="small"
              className={s.activityButton}
              onClick={() => removeActivity(pseudoId)}
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          </div>
        </div>
      </div>
    ))
  );

  const renderEmptyActivitiesList = () => _.isEmpty(activityList) && !isAddingActivityInProgress && (
    <p className={s.emptyActivitiesListWar}>
      <span>
        If no activity is given, than activity with name
        {' '}
        <b>&quot;General&quot;</b>
        {' '}
        and
      </span>
      <span className={s.generalColor} />
      <span>color will be added automatically</span>
    </p>
  );

  return (
    <Modal
      className={modals.qsBasicAntdModal}
      visible={visible}
      onCancel={() => onVisibleChange(false)}
      title="Add new Partner"
      onOk={savePartner}
      confirmLoading={isLoading}
      okButtonProps={{
        size: 'large',
        className: buttons.qsButtonPrimary,
        disabled: errorHelper.isWithErrors(),
      }}
      cancelButtonProps={{
        size: 'large',
        className: buttons.qsButton,
      }}
      okText="Add"
      centered
      closable={false}
    >
      <div className={s.newPartnerInputsWrapper}>
        { renderNameField() }
        { renderDescriptionField() }
        { renderRequestedByField() }
      </div>

      <Space direction="vertical" className={cx(s.appearanceBlockWrapper, { [inputs.withError]: errorHelper.isErrorField('color') })}>
        <div className={s.appearanceTitleBlock}>
          <span className={s.fieldName}>Appearance</span>
          <Button
            type="text"
            className={s.partnerCardButton}
            onClick={setRandomPartnerColorAndMask}
          >
            <span>Randomize</span>
            <RandomizeIcon />
          </Button>
        </div>
        <div className={s.colorErrorField}>
          {errorHelper.renderError('color')}
        </div>

        <PartnerCustomization
          onChangeMask={setPartnerMask}
          onChangeColor={setPartnerColor}
          maskColor={maskColor}
          partnerMask={fields.mask}
          partnerColor={fields.color}
          disabledColors={disabledColors}
        />
      </Space>

      <div className={s.activitiesSection}>
        <div className={s.appearanceTitleBlock}>
          <span className={s.fieldName}>Activities</span>
          <Button
            type="text"
            className={s.partnerCardButton}
            onClick={addNewActivity}
          >
            <span>Add</span>
            <FontAwesomeIcon icon={[ 'fas', 'plus-circle' ]} />
          </Button>
        </div>

        <div className={s.activitiesList}>
          {renderEmptyActivitiesList()}
          {renderActivities()}
        </div>
      </div>
    </Modal>
  );
};

export default NewPartnerModal;
