import React, { useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { Button, List } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import PartnerMask from 'components/_shared/PartnerMask';
import { UserPinnedActivityOutput } from 'api/Partners/types';
import { ReactComponent as ActiveActivityIcon } from 'helpers/icons/activity-finger.svg';
import { ReactComponent as DragIndicatorIcon } from 'helpers/icons/DragIndicator.svg';
import { ReactComponent as RemoveActivityIcon } from 'helpers/icons/minus-circle.svg';
import { removeActivityFromPinnedList } from 'api/Partners/requests';
import SpinnerNew from 'components/_shared/LoadingSpinnerNew';
import { DispatchType, Redux } from 'helpers/types/_common';
import { markActivityAsCurrentlyInUse } from 'redux/TimeSheet/action';

import s from './helpers/styles.module.sass';
import { UiDrawMode } from '../../../../../../../helpers/constants/_common/constants';

interface ActivityListItemProps {
  userId: number;
  activityObject: UserPinnedActivityOutput;
  collapsed: boolean;
  isEdit: boolean;
  handleSetPinnedActivity: (activityObject?: UserPinnedActivityOutput) => void;
  getActivities: () => void;
  curatorOnlyView?: boolean;
}

const ActivityListItem: React.FC<ActivityListItemProps> = (props) => {
  const {
    activityObject, collapsed, isEdit, handleSetPinnedActivity,
    userId, getActivities, curatorOnlyView,
  } = props;

  const { selectedActivity, activities, tableSettings } = useSelector((state: Redux) => state.timeSheet);
  const { preferences } = useSelector((state: Redux) => state.login);

  const [ unpinLoading, setUnpinLoading ] = useState(false);

  const dispatch: DispatchType = useDispatch();

  const isMouseMode = preferences.webUiDrawingMode !== UiDrawMode.MOUSE_AND_CTRL;
  const currentlyInUseActivity = _.filter(activities, { currentlyInUse: true })[0];
  const comparableActivity = isMouseMode ? selectedActivity : currentlyInUseActivity;

  const isActiveActivity = !isEdit && !tableSettings.isMonthClosed && _.isEqual(activityObject.activity.activityId, comparableActivity?.activity?.activityId);

  const handleOnItemClick = () => {
    if (isEdit || tableSettings.isMonthClosed || curatorOnlyView) {
      return;
    }

    if (isActiveActivity) {
      handleSetPinnedActivity(undefined);
    } else if (isMouseMode) {
      handleSetPinnedActivity(activityObject);
    }

    dispatch(markActivityAsCurrentlyInUse(activityObject, userId));
  };

  const handleOnRemoveActivity = () => {
    setUnpinLoading(true);

    removeActivityFromPinnedList({ userId, activityObject })
      .then(() => getActivities())
      .finally(() => setUnpinLoading(false));
  };

  const avatar = (
    <div>
      <PartnerMask
        wrapperColor={activityObject.activity.partner.color}
        mask={activityObject.activity.partner.mask}
        partnerId={activityObject.activity.partner.partnerId}
        iconColor={activityObject.activity.color}
      />
      { (collapsed && isActiveActivity) ? <ActiveActivityIcon className={s.collapsedActivityIcon} /> : null }
    </div>
  );

  const renderRemoveButton = () => {
    if (collapsed || !isEdit) {
      return null;
    }

    return (
      <Button
        type="text"
        size="small"
        className={s.removeActivityButton}
        title="Remove Activity"
        onClick={handleOnRemoveActivity}
      >
        { unpinLoading ? <SpinnerNew small /> : <RemoveActivityIcon className={s.removeActivityIcon} /> }
      </Button>
    );
  };

  const renderDragIcon = () => {
    if (collapsed || !isEdit) {
      return null;
    }

    return <DragIndicatorIcon className={s.dragIndicator} />;
  };

  return (
    <List.Item
      className={cx(s.activityListItem, {
        [s.activeActivity]: isActiveActivity,
        [s.collapsedActivityItem]: collapsed,
        [s.editModeActivityItem]: isEdit,
        [s.monthClosedActivityItem]: tableSettings.isMonthClosed || curatorOnlyView,
      })}
      title={`${activityObject.activity.partner.name}: ${activityObject.activity.name}`}
      onClick={handleOnItemClick}
    >
      <List.Item.Meta
        className={s.activityListItemMeta}
        description={(
          <div className={s.activityListItemDescription}>
            <div className={s.descriptionTextWrapper}>
              { renderDragIcon() }
              {avatar}
              <span className={s.descriptionText}>{`${activityObject.activity.partner.name}: ${activityObject.activity.name}`}</span>
            </div>

            { (isActiveActivity && !collapsed) ? <ActiveActivityIcon className={s.activeActivityIcon} /> : null }
            { renderRemoveButton() }
          </div>
        )}
      />
    </List.Item>
  );
};

export default ActivityListItem;
