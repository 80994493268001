import { List } from 'antd';
import React from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import PartnerMask from 'components/_shared/PartnerMask';
import { Redux } from 'helpers/types/_common';
import { UserPinnedActivityOutput } from 'api/Partners/types';

import S from './helpers/styles.module.sass';
import { ChosenActivityInfo } from '../../../../helpers/types';

const cx = classNames.bind(S);

interface ActivityListItemProps {
  activityObject: UserPinnedActivityOutput;
  handleSave: (activity: ChosenActivityInfo) => void;
}

const ActivityListItem: React.FC<ActivityListItemProps> = (props) => {
  const { activityObject, handleSave } = props;
  const { tableSettings } = useSelector((state: Redux) => state.timeSheet);

  const onClickHandler = () => {
    if (tableSettings.isMonthClosed) {
      return;
    }

    handleSave({
      activityId: activityObject.activity.activityId,
      partnerId: activityObject.activity.partner.partnerId,
      partnerName: activityObject.activity.partner.name,
      activityName: activityObject.activity.name,
      mask: activityObject.activity.partner.mask,
      partnerColor: activityObject.activity.partner.color,
      activityColor: activityObject.activity.color,
    });
  };

  return (
    <List.Item
      key={activityObject.userPinnedActivityId}
      className={cx(S.activityListItem, S.withPadding, {
        [S.monthClosedActivityItem]: tableSettings.isMonthClosed,
      })}
      title={`${activityObject.activity.partner.name}: ${activityObject.activity.name}`}
      onClick={onClickHandler}
    >
      <List.Item.Meta
        className={S.activityListItemMeta}
        description={(
          <div className={S.activityListItemDescription}>
            <PartnerMask
              wrapperColor={activityObject.activity.partner.color}
              mask={activityObject.activity.partner.mask}
              wrapperClassName={S.activityIconWrapper}
              partnerId={activityObject.activity.partner.partnerId}
              iconColor={activityObject.activity.color}
            />
            <p className={S.descriptionTextWrapper}>
              <span className={S.descriptionText}>{`${activityObject.activity.partner.name}: ${activityObject.activity.name}`}</span>
            </p>
          </div>
        )}
      />
    </List.Item>
  );
};

export default ActivityListItem;
