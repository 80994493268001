import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import qs from 'query-string';

import TokenStore from 'api/_request/helpers/jwtTokenStore';

import { DispatchType, Redux } from '../../helpers/types/_common';
import { authByAccessToken, googleAuth } from '../../V1/actions/Login/LoginActions';
import SpinnerNew from '../_shared/LoadingSpinnerNew';
import { AuthLocaleParams } from '../../api/Auth/types';
import { deleteFromSessionStorage } from '../../helpers/utils/sessionStorageService';
import { REDIRECT_URL } from '../../helpers/constants/_common/sessionStorageConstants';
import { DEFAULT_PATH, PATH_TRACKER } from '../../helpers/constants/_common/MenuCases';

interface AppAuthWrapperProps {
  children: React.ReactElement<any, any> | null,
}

const AppAuthWrapper: React.FC<AppAuthWrapperProps> = ({ children }) => {
  const accessToken = TokenStore.getAccessToken();

  const dispatch: DispatchType = useDispatch();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const localeParams: AuthLocaleParams = qs.parse(search);

  const { userDataRequest, userId, isFilledProfile } = useSelector((state: Redux) => state.login);

  const hasAccessTokenAndLogged = accessToken && userId;
  const hasAccessTokenAndNotLogged = accessToken && !userId;
  const rootDirectory = pathname?.length <= 1;

  useEffect(() => {
    if (history.action !== 'PUSH') {
      deleteFromSessionStorage(REDIRECT_URL);
    }

    if (hasAccessTokenAndNotLogged) {
      dispatch(authByAccessToken());

      return;
    }

    getUserData();
  }, []);

  const getUserData = () => {
    const redirectUri = `${window.location.protocol}//${window.location.host}`;

    if (!localeParams?.code) {
      return;
    }

    dispatch(googleAuth({
      code: localeParams.code,
      state: localeParams.state,
      redirectUri,
    }));
  };

  if (hasAccessTokenAndLogged && rootDirectory && isFilledProfile) {
    return <Redirect to={PATH_TRACKER} />;
  }

  if (!accessToken) {
    if (!rootDirectory) {
      return <Redirect to={DEFAULT_PATH} />;
    }
  }

  if (hasAccessTokenAndNotLogged || localeParams?.code || userDataRequest.isLoading) {
    return <SpinnerNew />;
  }

  return children;
};

export default AppAuthWrapper;
