import React, { useEffect, useState } from 'react';
import { Modal, Select, Space } from 'antd';
import _ from 'lodash';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import modals from 'helpers/styles/components/modals.module.sass';
import buttons from 'helpers/styles/components/buttons.module.sass';
import selects from 'helpers/styles/components/selects.module.sass';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import PartnerMask from 'components/_shared/PartnerMask';
import { DispatchType, Redux } from 'helpers/types/_common';
import { replaceTimesheetActivity } from 'api/TimeSheet/request';
import { replaceTimesheetActivityWithNew } from 'redux/TimeSheet/action';
import { BCMonth, UsersHourResponse } from 'api/TimeSheet/types';
import { getMonthNameByNumber } from 'components/_shared/BusinessMonthSelector/helpers/helpers';

import s from './styles.module.sass';
import { MonthInfoModalState } from '../../helpers/types';
import { TimesheetActivitiesHours } from '../MonthInfoPopover/helpers/helpers';

interface MonthInfoReplaceModalProps {
  userId: number;
  modalInfo: MonthInfoModalState;
  setModalInfo: React.Dispatch<React.SetStateAction<MonthInfoModalState>>;
  selectedActivities: TimesheetActivitiesHours;
  BCMonth: BCMonth;
}

const MonthInfoReplaceModal: React.FC<MonthInfoReplaceModalProps> = ({ modalInfo, setModalInfo, selectedActivities, userId, BCMonth }) => {
  const dispatch: DispatchType = useDispatch();

  const { tableSettings, activities } = useSelector((state: Redux) => state.timeSheet);

  const [ currentActivityId, setCurrentActivityId ] = useState<number | null>(null);
  const [ targetActivityId, setTargetActivityId ] = useState<number | null>(null);
  const [ confirmLoading, setConfirmLoading ] = useState(false);

  useEffect(() => {
    if (modalInfo.partner) {
      setCurrentActivityId(modalInfo.partner.activity.activityId);
    }
  }, [ modalInfo.partner ]);

  const handleOnClose = () => {
    setCurrentActivityId(null);
    setTargetActivityId(null);
    setConfirmLoading(false);

    setModalInfo({
      visible: false,
      partner: null,
    });
  };

  const handleOnChangeActivity = () => {
    if (_.isNull(targetActivityId) || _.isNull(currentActivityId)) {
      return;
    }

    setConfirmLoading(true);

    return replaceTimesheetActivity({
      userId,
      activityId: currentActivityId,
      newActivityId: targetActivityId,
      selectedDateObj: tableSettings.selectedDateObj,
    })
      .then((res: UsersHourResponse[]) => dispatch(replaceTimesheetActivityWithNew(res)))
      .finally(() => handleOnClose());
  };

  const selectedPartnersOptions = _.map(selectedActivities, activity => (
    <Select.Option key={activity.activity.activityId} value={activity.activity.activityId}>
      <Space>
        <PartnerMask
          wrapperColor={activity.partner.color}
          iconColor={activity.activity.color}
          partnerId={activity.partner.partnerId}
          mask={activity.partner.mask}
        />
        {`${activity.partner.name}: ${activity.activity.name}`}
      </Space>
    </Select.Option>
  ));

  const activitiesOptions = _.map(activities, activity => (
    <Select.Option key={activity.activity.activityId} value={activity.activity.activityId}>
      <Space>
        <PartnerMask
          wrapperColor={activity.activity.partner.color}
          iconColor={activity.activity.color}
          partnerId={activity.activity.partner.partnerId}
          mask={activity.activity.partner.mask}
        />
        {`${activity.activity.partner.name}: ${activity.activity.name}`}
      </Space>
    </Select.Option>
  ));

  const modalContent = () => {
    if (!modalInfo.partner) {
      return null;
    }

    const BCMonthTitle = getMonthNameByNumber(BCMonth.month);
    const isModifiedMonth = BCMonth.modified ? ' (Modified)' : '';

    return (
      <div className={s.monthInfoBodyWrapper}>
        <div>
          <Space direction="vertical" className={s.monthInfoSelectWrapper}>
            <span>Current activity</span>
            <Select
              value={currentActivityId}
              className={cx(selects.qsSelect, s.monthInfoSelect)}
              size="large"
              dropdownClassName={selects.selectDropdown}
              suffixIcon={<SelectIcon />}
              bordered={false}
              onChange={setCurrentActivityId}
            >
              {selectedPartnersOptions}
            </Select>
          </Space>
        </div>
        <div>
          <Space direction="vertical" className={s.monthInfoSelectWrapper}>
            <span>Replace with</span>
            <Select
              value={targetActivityId}
              placeholder="Select activity"
              size="large"
              dropdownClassName={selects.selectDropdown}
              className={cx(selects.qsSelect, s.monthInfoSelect)}
              suffixIcon={<SelectIcon />}
              bordered={false}
              onChange={setTargetActivityId}
            >
              {activitiesOptions}
            </Select>
          </Space>
        </div>

        <div className={s.replaceActivityAlert}>
          <FontAwesomeIcon icon="exclamation-circle" style={{ fontSize: 20 }} />
          <span>
            {`All time entries with this activity in ${BCMonthTitle} ${BCMonth.year} ${isModifiedMonth} will be replaced!`}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="Replace activity"
      visible={modalInfo.visible}
      onOk={handleOnChangeActivity}
      onCancel={handleOnClose}
      className={modals.qsBasicAntdModal}
      okButtonProps={{
        size: 'large',
        loading: confirmLoading,
        disabled: _.isNull(targetActivityId) || _.isNull(currentActivityId),
        className: buttons.qsButtonPrimary,
      }}
      cancelButtonProps={{
        size: 'large',
        className: buttons.qsButton,
      }}
      centered
    >
      { modalContent() }
    </Modal>
  );
};

export default MonthInfoReplaceModal;
