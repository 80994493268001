import React, { useEffect, useState } from 'react';
import { Drawer, Row, Space, Spin, Col, Skeleton, Button as AntdButton, Button, Typography } from 'antd';
import classNames from 'classnames/bind';
import _ from 'lodash';
import Paragraph from 'antd/es/typography/Paragraph';

import SkillAttitude from 'components/ProfileTabContent/components/SkillSet/components/Attitude';
import buttons from 'helpers/styles/components/buttons.module.sass';
import { ReactComponent as ShowLess } from 'helpers/icons/Buttons/ShowLess.svg';
import { ReactComponent as ShowMore } from 'helpers/icons/Buttons/ShowMore.svg';
import { ReactComponent as SlackIcon } from 'helpers/icons/companies/Slack.svg';
import { ReactComponent as SkypeIcon } from 'helpers/icons/companies/Skype.svg';
import { ReactComponent as TelegramIcon } from 'helpers/icons/companies/Telegram.svg';
import { EnglishLevelEnum } from 'helpers/types/_common';
import { renderSkillUpdatedDays } from 'components/_shared/OverdueBadge/helpers/helpers';
import { getUserSkillsRecursively } from 'api/SkillSet/requests';
import useApi from 'api/hooks/useApi/useApi';
import SKILL_EXPERIENCES from 'helpers/constants/Skills/Experience';
import { SkillExperienceEnum, SkillExperiencesConstant } from 'helpers/types/Skills';
import { EmployeeOccupationNames } from 'helpers/constants/Occupations/types';
import { getUserStatisticsById } from 'api/User/requests';
import { UserDetailedStatistic, UserSkillOutput } from 'api/User/types';
import { ENGLISH_KNOWLEDGE_TITLE } from 'helpers/constants/Skills/EnglishKnowledgeStates';

import { convertTextToLink, utcOffsetToCurrentTime } from './helpers/helpers';
import EngKnowledgeLevel from '../../_shared/EngKnowledgeLevel';
import SkillTag from '../../_shared/SkillTag';
import { EmployeeDrawerProps, ExperienceObject } from './helpers/types';
import S from './helpers/styles.module.sass';
import TrackerLogo from '../../_shared/TrackerLogo/TrackerLogo';
import { SLACK_GROUP_ID } from '../../../helpers/constants/_common/constants';

const cx = classNames.bind(S);

const EmployeeDrawer: React.FC<EmployeeDrawerProps> = (props) => {
  const { onClose, className, drawerData, externalButtons } = props;

  const [ isShowAll, setIsShowAll ] = useState(false);

  const [ getUserStatistics, user, loading, setUser ] = useApi<typeof getUserStatisticsById, UserDetailedStatistic | undefined>(getUserStatisticsById);
  const [ getUserSkills, skills, skillLoading, setSkills ] = useApi<typeof getUserSkillsRecursively, UserSkillOutput[] | undefined>(getUserSkillsRecursively);

  useEffect(() => {
    if (drawerData.visible && drawerData?.userId) {
      getUserStatistics(drawerData.userId);
      getUserSkills(drawerData.userId);
    }

    if (!drawerData.visible) {
      setUser(undefined);
      setSkills(undefined);
      setIsShowAll(false);
    }
  }, [ drawerData.visible ]);

  const renderHeader = () => {
    const renderLogo = () => (
      user?.avatarUrl
        ? <img src={user?.avatarUrl} alt="avatar" className={cx('author-avatar')} />
        : <TrackerLogo className={cx('author-avatar', 'author-avatar--qs')} />
    );

    const customTitle = user?.title ? <span className={S.customTitle}>{user?.title}</span> : '';
    const occupation = `${user?.occupation ? `${EmployeeOccupationNames[user?.occupation]}` : 'Unknown'}`;
    const mainSkill = user?.primarySkill?.skill?.name || '';

    return (
      <Space className={cx('drawer-content_header')} size={24}>
        {renderLogo()}
        <Space direction="vertical" size={10}>
          <div className={cx('header_name')}>
            {`${_.upperFirst(user?.forename)} ${_.upperFirst(user?.surname)}`}
          </div>

          <span className={cx('header_occupation')}>
            {customTitle}
            {user?.title ? <span className={cx('title-splitter')}>•</span> : '' }
            <span>
              {`${mainSkill} ${occupation}`}
            </span>
          </span>

          <span className={cx('header_occupation')}>
            {
              _.map(user?.entities, 'name').join(' • ')
            }
          </span>
        </Space>
      </Space>
    );
  };

  const renderSlackButton = (value?: string) => (
    <a
      href={value && `slack://user?team=${SLACK_GROUP_ID}&id=${value}`}
      target="_blank"
      rel="noreferrer"
      title={value ? 'Slack link' : 'Not specified by user'}
      className={cx(S.socialNetworkButton, { [S.disabled]: !value })}
    >
      <SlackIcon />
    </a>
  );

  const renderSkypeButton = (value?: string) => (
    <a
      href={value && `skype:${value}?chat`}
      target="_blank"
      rel="noreferrer"
      title={value ? 'Skype link' : 'Not specified by user'}
      className={cx(S.socialNetworkButton, { [S.disabled]: !value })}
    >
      <SkypeIcon />
    </a>
  );

  const renderTelegramButton = (value?: string) => (
    <a
      href={value && `https://t.me/${value}`}
      target="_blank"
      rel="noreferrer"
      title={value ? 'Telegram link' : 'Not specified by user'}
      className={cx(S.socialNetworkButton, { [S.disabled]: !value })}
    >
      <TelegramIcon />
    </a>

  );

  const renderField = (title: string, value?: string | number | React.ReactNode) => {
    const fieldLink = convertTextToLink(title, value);

    return (
      <Space direction="vertical" size={4}>
        <span className={cx('field_title')}>{title}</span>
        <Typography.Text className={cx('field_body')}>{fieldLink}</Typography.Text>
      </Space>
    );
  };

  const renderShowAllButton = () => {
    if ((skills && skills.length < 5) || skillLoading) {
      return null;
    }

    return (
      <AntdButton
        type="text"
        className={cx('show-all-button')}
        onClick={() => setIsShowAll(!isShowAll)}
      >
        <span className={cx('show-all-button_icon')}>
          {isShowAll ? <ShowLess /> : <ShowMore />}
        </span>
        <span>
          { isShowAll ? 'Show less' : 'Show All' }
        </span>

      </AntdButton>
    );
  };

  const renderSkillSet = () => {
    const renderFirstFiveSkills = () => {
      if (skillLoading) {
        return <Skeleton active />;
      }

      if (_.isEmpty(skills)) {
        return <div className={cx('empty-field')}>—</div>;
      }

      return _.map(_.slice(skills, 0, 5), s => (
        <SkillTag
          color={SKILL_EXPERIENCES[s.experience as SkillExperienceEnum]?.color}
          title={(
            <Space>
              <span className="truncated-text" title={s.skill.name}>{s.skill.name}</span>
              <SkillAttitude iconName={s.attitude} />
            </Space>
          )}
          key={s.skill.skillId || s.skill.name}
        />
      ));
    };

    const experienceObject = _.reduce(skills, (acc, v) => {
      const skillWithAttitudes = {
        ...v,
        ...v.skill,
        attitude: v.attitude,
      };

      return ({
        ...acc,
        [v.experience]: acc[v.experience] ? [ ...acc[v.experience], skillWithAttitudes ] : [ skillWithAttitudes ],
      });
    }, {} as ExperienceObject);

    const renderExpTags = () => {
      if (skillLoading) {
        return <Skeleton active />;
      }

      if (_.isEmpty(experienceObject)) {
        return <div className={cx('empty-field')}>—</div>;
      }

      const skillKeys = _.intersection(_.reverse(_.keys(SKILL_EXPERIENCES)), _.keys(experienceObject));

      return _.map(skillKeys, (skillKey: string) => {
        const exp = experienceObject[skillKey];

        const expTags = _.map(exp, v => (
          <SkillTag
            color={SKILL_EXPERIENCES[v.experience as SkillExperienceEnum]?.color}
            title={(
              <Space>
                <span className="truncated-text" title={v.name}>{v.name}</span>
                <SkillAttitude iconName={v.attitude} />
              </Space>
            )}
            key={v.skillId || v.name}
          />
        ));

        return (
          <div key={skillKey} className={cx('skillset-field')}>
            <div className={cx('skillset-field_title')}>
              {SKILL_EXPERIENCES[skillKey as keyof SkillExperiencesConstant].label}
            </div>
            <div className={cx('skillset-field_tags')}>
              {expTags}
            </div>
          </div>
        );
      });
    };

    const renderEnglishKnowledge = () => {
      const englishKnowledgeTitle = () => {
        if (!user?.englishKnowledge || user?.englishKnowledge === EnglishLevelEnum.Zero) {
          return null;
        }

        return (
          <span>{ENGLISH_KNOWLEDGE_TITLE[user?.englishKnowledge]}</span>
        );
      };

      return (
        <div className={cx('english-field')}>
          <div className={cx('english-field_title')}>English</div>
          <SkillTag
            className={cx('english-skill-tag')}
            title={(
              <Space size={5}>
                <EngKnowledgeLevel englishKnowledgeLevel={user?.englishKnowledge} size={14} engViewBox="0 0 24 18" />
                {englishKnowledgeTitle()}
              </Space>
            )}
          />
        </div>
      );
    };

    return (
      <div className={cx('drawer-content_category')}>
        <div className={cx('category_title', { showAll: true })}>
          <span>Skillset</span>
          { renderShowAllButton() }
        </div>
        <div className={cx('category_body')}>
          <div className={cx('category_body_skills')}>
            { isShowAll ? renderExpTags() : renderFirstFiveSkills() }
          </div>
          { renderEnglishKnowledge() }
        </div>
      </div>
    );
  };

  const renderStats = () => (
    <div className={cx('drawer-content_category')}>
      <div className={cx('category_title')}>Stats</div>
      <div className={cx('category_body')}>
        <div>
          <Row gutter={[ 24, 28 ]}>
            <Col span={8}>{renderField('Reported this month', `${user?.currentMonthHours}h`)}</Col>
            <Col span={8}>{renderField('Skill Tags', user?.skillsCount)}</Col>
            <Col span={8}>{renderField('Skillset updated', renderSkillUpdatedDays(user?.skillsetLastUpdate))}</Col>
          </Row>
        </div>
      </div>
    </div>
  );

  const renderAbout = () => (
    <div className={cx('drawer-content_category')}>
      <div className={cx('category_title')}>About</div>
      <div className={cx('category_body')}>
        <div>
          <Row>
            <Paragraph ellipsis={{ rows: 5, expandable: true, symbol: 'more' }}>
              {user?.description || '—'}
            </Paragraph>
          </Row>
          <Row gutter={[ 24, 28 ]}>
            <Col span={8}>{renderField('Location', user?.location)}</Col>
            <Col span={8}>{renderField('Current time', utcOffsetToCurrentTime(user?.timezoneUtcOffset))}</Col>
          </Row>
        </div>
      </div>
    </div>
  );

  const renderContacts = () => (
    <div className={cx('drawer-content_category')}>
      <div className={cx('category_title')}>Contacts</div>
      <div className={cx('category_body')}>
        <Row gutter={[ 24, 28 ]}>
          <Col span={12}>{renderField('Mobile', user?.phone)}</Col>
          <Col span={12}>
            <Space direction="vertical" size={4}>
              <span className={cx('field_title')}>Email</span>
              <Typography.Text className={cx('field_body')}><a href={`mailto:${user?.emailAddress}`}>{user?.emailAddress}</a></Typography.Text>
              {user?.secondaryEmailAddress && <Typography.Text className={cx('field_body')}><a href={`mailto:${user?.secondaryEmailAddress}`}>{user?.secondaryEmailAddress}</a></Typography.Text>}
            </Space>
          </Col>

          <Col span={12}>
            <Space direction="vertical" size={4}>
              <span className={cx('field_title')}>Messengers</span>

              <Space>
                {renderSlackButton(user?.slackMemberId)}
                {renderSkypeButton(user?.skypeId)}
                {renderTelegramButton(user?.telegramUsername)}
              </Space>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );

  const renderDrawerContent = () => {
    if (_.isEmpty(user)) {
      return null;
    }

    return (
      <div className={cx('drawer-content')}>
        {renderHeader()}
        {renderSkillSet()}
        {renderStats()}
        {renderAbout()}
        {renderContacts()}
      </div>
    );
  };

  const renderFooter = () => {
    const footerButtons = _.map(externalButtons, action => action(user));

    return (
      <div className={S.employeeDrawerFooter}>
        <div>
          {_.isEmpty(user) ? null : footerButtons}
        </div>
        <Button
          className={buttons.qsButton}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    );
  };

  const omittedProps = _.omit(props, [ 'userId', 'drawerData', 'externalButtons', 'visible' ]);

  return (
    <Drawer
      {...omittedProps}
      visible={drawerData.visible}
      width={715}
      className={cx('employee-drawer', className)}
      footer={renderFooter()}
      destroyOnClose
    >
      <Spin spinning={loading}>
        {renderDrawerContent()}
      </Spin>
    </Drawer>
  );
};

export default EmployeeDrawer;
