import React, { useState } from 'react';
import { Drawer, Spin, Typography } from 'antd';
import classNames from 'classnames/bind';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { DispatchType, Redux } from 'helpers/types/_common';
import PartnerMask from 'components/_shared/PartnerMask';
import withRightPlural from 'helpers/utils/withRightPlural';
import { initiateContextMenus, dropTimeSelecting, setReplaceDrawer, addUserHourAction } from 'redux/TimeSheet/action';
import { HoursDrawerMode } from 'redux/TimeSheet/types';
import { HoursDataToAdd } from 'api/TimeSheet/types';
import { getSelectionHoursStat } from 'components/TimeSheet/components/Table/components/TableBody/helpers/helpers';
import ChooseHoursActionModal from 'components/TimeSheet/components/Table/components/TableBody/components/ChooseHoursActionModal/ChooseHoursActionModal';
import { SelectedHour } from 'components/TimeSheet/helpers/types';

import S from './helpers/styles.module.sass';
import { MarkedHourDrawerProps } from './helpers/types';
import ReplaceHourActivitiesList from './components/ReplaceHourActivitiesList/ReplaceHourActivitiesList';
import ReplaceHourPartnersList from './components/ReplaceHourPartnersList/ReplaceHourPartnersList';

const cx = classNames.bind(S);

interface ConfirmModalData {
  isVisible: boolean;
  selectedHoursAmount: number;
  emptyHoursAmount: number;
  userId: number;
  confirmType: 'replace' | 'replace&fill',
  data: {
    day: string,
    startCellIndex: number,
    endCellIndex: number,
    activityId: number,
  },
  onOk: any;
}

const confirmModalInitData: ConfirmModalData = {
  isVisible: false,
  selectedHoursAmount: 0,
  emptyHoursAmount: 0,
  confirmType: 'replace',
  userId: 0,
  data: {
    day: '',
    startCellIndex: 1,
    endCellIndex: 1,
    activityId: 1,
  },
  onOk: () => {},
};

const MarkedHourDrawer: React.FC<MarkedHourDrawerProps> = (props) => {
  const { className, isLoading, drawerData, user, mode } = props;
  const [ confirmModalData, setConfirmModalData ] = useState<ConfirmModalData>(confirmModalInitData);

  const dispatch: DispatchType = useDispatch();

  const { activities, partners } = useSelector((state: Redux) => state.timeSheet);

  const drawerMode = mode;
  const { total: totalCellAmount, empty: emptyCellAmount, filled: filledCellAmount } = getSelectionHoursStat();

  const handleClose = () => {
    dispatch(dropTimeSelecting());
    dispatch(initiateContextMenus());
    dispatch(setReplaceDrawer());
  };

  const handleSave = (activityId: number) => {
    const { selectionDay, startCellIndex, endCellIndex } = drawerData;
    
    if (_.isNull(selectionDay)) return;

    if (drawerMode === HoursDrawerMode.Add) {
      return addHandle(activityId).then(() => handleClose());
    }

    setConfirmModalData({
      isVisible: true,
      selectedHoursAmount: filledCellAmount || (endCellIndex - startCellIndex + 1) / 2,
      emptyHoursAmount: emptyCellAmount,
      userId: user.userId,
      confirmType: drawerMode === HoursDrawerMode.Replace ? 'replace' : 'replace&fill',
      data: {
        startCellIndex,
        endCellIndex,
        activityId,
        day: selectionDay,
      },
      onOk: () => {
        setConfirmModalData(confirmModalInitData);
        handleClose();
        dispatch(dropTimeSelecting());
      },
    });
  };

  const addHandle = (activityId: number) => {
    dispatch(setReplaceDrawer({ isLoading: true }));

    const { startCellIndex, endCellIndex, selectionDay } = drawerData;
    
    if (_.isNull(selectionDay)) return;
    
    const data: HoursDataToAdd = { activityId, startCellIndex, endCellIndex, day: selectionDay };

    return dispatch(addUserHourAction(user.userId.toString(), data));
  };

  const renderActivity = (selectedHour: SelectedHour) => {
    const { activity, partner } = selectedHour.usersHourData;
    const hoursAmount = Math.abs(selectedHour.endCellIndex - selectedHour.startCellIndex + 1) / 2;

    return (
      <div key={selectedHour.timesheetId || activity?.activityId} className={cx(S.activity, S.withPadding, S.selected)}>
        <PartnerMask
          partnerId={partner?.partnerId}
          mask={partner?.mask}
          iconColor={activity?.color}
          wrapperClassName={S.activityIconWrapper}
          style={{ borderColor: activity?.color, backgroundColor: partner?.color }}
        />
        <p className={S.activityText}>
          <Typography.Text className={S.activityLabel}>{`${partner?.name}: ${activity?.name}`}</Typography.Text>
          <Typography.Text className={S.hours}>{`${hoursAmount} ${withRightPlural('hour', hoursAmount)}`}</Typography.Text>
        </p>
      </div>
    );
  };

  const renderHead = () => {
    if (drawerData.selectedHours.length === 1) {
      return (
        <>
          {emptyCellAmount !== 0 && (
            <div className={cx(S.sum, S.withPadding)}>
              {`${emptyCellAmount} empty ${withRightPlural('hour', emptyCellAmount)} selected`}
            </div>
          )}
          {renderActivity(drawerData.selectedHours[0])}
        </>
      );
    } else {
      return (
        <div className={cx(S.sum, S.withPadding)}>
          {`${totalCellAmount} ${withRightPlural('hour', totalCellAmount)} selected`}
        </div>
      );
    }
  };

  const renderBody = () => (
    <div className={S.drawerContentBody}>
      <ReplaceHourActivitiesList
        activities={activities}
        handleSave={handleSave}
      />

      <ReplaceHourPartnersList
        handleSave={handleSave}
        partners={partners}
      />
    </div>
  );

  const renderDrawerContent = () => (
    <div className={S.drawerContent}>
      {renderHead()}
      {renderBody()}
    </div>
  );

  const renderTitle = () => {
    switch (drawerMode) {
      case HoursDrawerMode.Add:
        return 'Add activity';
      case HoursDrawerMode.ReplaceAndFill:
        return 'Replace & add';
      default:
      case HoursDrawerMode.Replace:
        return 'Replace activity';
    }
  };

  const omittedProps = _.omit(props, [ 'drawerData', 'isLoading' ]);

  return (
    <Drawer
      title={renderTitle()}
      {...omittedProps}
      width={420}
      className={cx(S.drawer, className)}
      onClose={handleClose}
      destroyOnClose
    >
      <Spin spinning={isLoading} wrapperClassName={S.markedHourSpin}>
        {renderDrawerContent()}
      </Spin>
      <ChooseHoursActionModal
        {...confirmModalData}
        getContainer={document.body}
        onClose={() => {
          setConfirmModalData(confirmModalInitData);
        }}
      />
    </Drawer>
  );
};

export default MarkedHourDrawer;
