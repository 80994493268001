import qs from 'query-string';
import _ from 'lodash';

import { USER_ROLES } from 'api/User/constants';
import { AllUsersRequest } from 'api/User/types';
import { APIRequestParams } from 'api/_request/types';
import { GetPartnersUsersBody, PartnersUserOutput } from 'components/PartnersUsers/types';

import { convertObjectKeysToLanguageCase, getCorrectRequesterForPartnerEditing } from '../../helpers/utils/convertObject';
import {
  ActivityCardOutput,
  AddNewPartnerProps,
  FoundPartnerPage,
  GetPartnerParticipantsBody,
  NewActivity,
  NewParticipantBody,
  PartnerActiveStatusBulkProps,
  PartnerDetailsOutput, PartnerListBody,
  PartnersRequestBody,
  PinActivityBody,
  RemovePartnerBody,
  SetPinnedEntitiesBody,
  SettingActivityBody,
  UpdatePartnerProps,
  UserPartnerOutput,
  UserPartnerPage,
  UserPinnedActivityOutput,
  UserPinnedActivityOutputPage,
} from './types';
import { DEFAULT_PAGINATION_RAW, DefaultPaginationRaw, INCREASED_PAGINATION_RAW } from '../../helpers/constants/_common/Pagination';
import { setTimeTrackerActivity } from '../../redux/TimeSheet/action';
import { api } from '../../index';
import { convertPartnerParticipantsBody, convertPartnerUserBody, convertUserStatisticsBody } from './helpers';

const PARTNERS_REQUESTS = 'partnersRequests';

export const getPartnerById = async (partnerId: number | string) => api.get<PartnerDetailsOutput>(`/partners/${partnerId}/`);

export const getPartnersTable = (params: PartnersRequestBody) => api.get<FoundPartnerPage>('/partners/statistics/', convertUserStatisticsBody(params), {
  paramsSerializer: (params: object) => qs.stringify(params, { skipNull: true }),
  requestId: `${PARTNERS_REQUESTS}getPartners`,
});

export const getPartners = (params: PartnersRequestBody) => api.get<FoundPartnerPage>('/partners/', {
  active: true,
  ...convertObjectKeysToLanguageCase(params, 'snakeCase'),
}, { requestId: `${PARTNERS_REQUESTS}getPartners` });

export const getPartnersList = async (userId: number, body?: PartnerListBody) => {
  const partners: UserPartnerOutput[] = [];

  const recursivelyPartnersRequest = async (pagination: DefaultPaginationRaw) => {
    await api.get<UserPartnerPage>(`/users/${userId}/partners/`,
      {
        order_by: 'rank',
        active: true,
        role: body?.role || USER_ROLES.USER,
        ...pagination,
      }, {
        requestId: `${PARTNERS_REQUESTS}recursivelyPartnersRequest`,
      })
      .then((response: UserPartnerPage) => {
        partners.push(...response.objects);

        if (!response?.meta?.totalCount) {
          return;
        }

        if (response.meta.totalCount > partners.length) {
          return recursivelyPartnersRequest({
            limit: response.meta.limit,
            offset: response.meta.offset + response.meta.limit,
          });
        }
      });
  };

  await recursivelyPartnersRequest({
    limit: DEFAULT_PAGINATION_RAW.limit,
    offset: DEFAULT_PAGINATION_RAW.offset,
  });

  return partners;
};

export const getActivitiesList = async (userId: number) => {
  const activities: UserPinnedActivityOutput[] = [];

  const recursivelyGetActivities = async (pagination: DefaultPaginationRaw) => {
    await api.get<UserPinnedActivityOutputPage>(`/users/${userId}/pinned_activities/`,
      {
        order_by: 'rank',
        partners_active: true,
        activities_active: true,
        ...pagination,
      }, {
        requestId: `${PARTNERS_REQUESTS}recursivelyGetActivities`,
      })
      .then((response: UserPinnedActivityOutputPage) => {
        activities.push(...response.objects);

        if (!response?.meta?.totalCount) {
          return;
        }

        if (response.meta.totalCount > activities.length) {
          return recursivelyGetActivities({
            limit: response.meta.limit,
            offset: response.meta.offset + response.meta.limit,
          });
        }
      });
  };

  await recursivelyGetActivities({
    limit: INCREASED_PAGINATION_RAW.limit,
    offset: INCREASED_PAGINATION_RAW.offset,
  });

  return activities;
};

export const grantCuratorStatus = (partnerId: number, curatorId: number) => api.post(`/partners/${partnerId}/curators/${curatorId}/`);

export const revokeCuratorStatus = (partnerId: number, curatorId: number) => api.delete(`/partners/${partnerId}/curators/${curatorId}/`);

export const getPartnerParticipantsTable = (partnerId: number, body: GetPartnerParticipantsBody) => api.get(`/partners/${partnerId}/users/`, {
  ...convertPartnerParticipantsBody(body),
  email_address_contains: body.usernameContains,
  fullname_contains: body.usernameContains,
}, {
  paramsSerializer: (params: object) => qs.stringify(params, { skipNull: true }),
  requestId: `${PARTNERS_REQUESTS}getPartnerParticipants`,
});

// export const /

export const getPartnersUsersTable = (body: GetPartnersUsersBody) => api.get<PartnersUserOutput>('/partners/users/', convertPartnerUserBody(body), {
  paramsSerializer: (params: object) => qs.stringify(params, { skipNull: true }),
  requestId: `${PARTNERS_REQUESTS}getPartnersUsers`,
});

export const getPartnerActivities = (partnerId: number, active?: boolean) => api.get<ActivityCardOutput[]>(`/partners/${partnerId}/activities/`, !_.isUndefined(active) ? { active } : {});

export const addActivity = (partnerId: number, body: NewActivity) => api.post(`/partners/${partnerId}/activities/`, body);

export const updateActivity = (partnerId: number, activityId: number, body: Partial<ActivityCardOutput>) => api.patch(`/partners/${partnerId}/activities/${activityId}/`, body);

export const addUsersToPartner = (partnerId: number, userId: number, body: NewParticipantBody) => api.post(`/partners/${partnerId}/users/${userId}/`, convertObjectKeysToLanguageCase(body, 'snakeCase'));

export const unassignUserFromPartnerMultiply = (partnerId: number, userIds: number[]) => api.delete(`/partners/${partnerId}/users/`, { user_ids: userIds });

export const unassignUserFromPartner = (partnerId: number, userId: number) => api.delete(`/partners/${partnerId}/users/${userId}/`);

export const grantCuratorStatusMultiply = (partnerId: number, userIds: number[]) => api.post(`/partners/${partnerId}/curators/`, { user_ids: userIds });

export const revokeCuratorStatusMultiply = (partnerId: number, userIds: number[]) => api.delete(`/partners/${partnerId}/curators/`, { user_ids: userIds });

export const getPartnerCurators = (partnerId: number, limit: number = 10) => api.get(`/partners/${partnerId}/users/?role=CURATOR`, { limit });

export const getPartnerRequesters = async (body: any) => api.get<AllUsersRequest>('/partners/requesters', convertObjectKeysToLanguageCase(body, 'snakeCase'));

export const getPartnerAuthors = async (body: any) => api.get<AllUsersRequest>('/partners/authors/', convertObjectKeysToLanguageCase(body, 'snakeCase'));

export const addPartner = ({ userId, partnerId }: { userId: number, partnerId: number }) => api.post(`/users/${userId}/partners/${partnerId}/`);

export const addNewPartner = (data: AddNewPartnerProps) => api.post('/partners/', convertObjectKeysToLanguageCase(data, 'snakeCase'));

export const removeCuratorFromPartner = (partnerId: number, userId: number) => api.delete(`/partners/${partnerId}/curators/${userId}/`);

export const addCuratorToPartner = (partnerId: number, userId: number) => api.post(`/partners/${partnerId}/curators/${userId}/`);

export const addActivityFromPartnersList = ({ userId, activityId }: PinActivityBody) => api.post<UserPinnedActivityOutput>(`/users/${userId}/pinned_activities/${activityId}/`, {}, {
  requestId: `${PARTNERS_REQUESTS}addActivityFromPartnersList`,
}).then(res => res);

export const markActivityCurrentlyInUse = ({ userId, activity }: { userId: number, activity: UserPinnedActivityOutput }) => (
  api.patch<UserPinnedActivityOutput>(`/users/${userId}/pinned_activities/${activity.activity.activityId}/`, {
    rank: activity.rank,
    currently_in_use: !activity.currentlyInUse,
  })
);

export const setPinnedActivity = (activityObject?: UserPinnedActivityOutput) => setTimeTrackerActivity(activityObject);

export const removeActivityFromPinnedList = ({ userId, activityObject }: SettingActivityBody) => (
  api.delete(`/users/${userId}/pinned_activities/${activityObject?.activity?.activityId}/`)
);

export const removePartnerFromList = ({ userId, partnerObject }: RemovePartnerBody) => (
  api.delete(`/users/${userId}/partners/${partnerObject.partner.partnerId}/`)
);

export const setActivitiesAndPartnersAfterDrag = ({ userId, activityIds, partnerIds }: SetPinnedEntitiesBody) => Promise.all([
  api.put(`/users/${userId}/partners/ranks/`, { partner_ids: partnerIds }),
  api.put(`/users/${userId}/pinned_activities/ranks/`, { activity_ids: activityIds }),
]);

export const updatePartner = ({ partnerId, data, requestParams }: UpdatePartnerProps) => (
  api.patch(`/partners/${partnerId}/`, {
    ...getCorrectRequesterForPartnerEditing(data.requester),
    ..._.omit(data, 'requester'),
  }, requestParams)
);

export const setPartnerActiveStatusBulk = ({ partnerIds, status }: PartnerActiveStatusBulkProps, requestOptions?: APIRequestParams) => (
  api.put('/partners/activations/', { partner_ids: partnerIds, active: status }, requestOptions)
);
