import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import PartnerMask from 'components/_shared/PartnerMask';
import { DispatchType, Redux } from 'helpers/types/_common';
import { deleteUserHourAction } from 'redux/TimeSheet/action';
import { getDateAndCellIndexByTime } from 'components/TimeSheet/helpers/helpers';
import modals from 'helpers/styles/components/modals.module.sass';
import withRightPlural from 'helpers/utils/withRightPlural';

import S from './styles.module.sass';
import { ModifiedUsersHour } from '../UserHoursList/helpers/groupUserHoursByActivity';
import { ReactComponent as CloseIcon } from '../../helpers/closeIcon.svg';
import { MobileTimesheetPanel } from '../../helpers/types';
import MainInnerPanel from './components/MainInnerPanel/MainInnerPanel';
import ReplaceActivityInnerPanel from './components/ReplaceActivityInnerPanel/ReplaceActivityInnerPanel';

interface UserHourModalProps {
  userHour: ModifiedUsersHour | null;
  isVisible: boolean;
  changePanel: (panelName: MobileTimesheetPanel) => void;
  refreshCalendar: () => void;
  onClose: () => void;
}

const UserHourModal: React.FC<UserHourModalProps> = ({ userHour, isVisible, onClose, changePanel, refreshCalendar }) => {
  const [ isLoading, setLoading ] = useState(false);
  const [ innerPanel, setInnerPanel ] = useState<'main' | 'replace'>('main');
  const user = useSelector((state: Redux) => state.login);
  const dispatch: DispatchType = useDispatch();

  useEffect(() => {
    if (!isVisible) {
      setInnerPanel('main');
    }
  }, [ isVisible ]);

  if (!userHour) return null;

  const isDeletingConfirmModalNeeded = user.preferences.askConfirmationOnDeleteHours;

  const start = getDateAndCellIndexByTime(userHour?.startTime);
  const end = getDateAndCellIndexByTime(userHour?.endTime);

  const { hours } = userHour;

  const deleteUserHourWithoutConfirm = () => {
    if (_.isNull(start.cellIndex) || _.isNull(start.day) || _.isNull(end.cellIndex) || _.isNull(end.day)) return;

    setLoading(true);
    dispatch(deleteUserHourAction(start.day, start.cellIndex, end.cellIndex, user.userId.toString()))
      .then(() => {
        changePanel(MobileTimesheetPanel.Regular);
        refreshCalendar();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteUserHourConfirmation = () => {
    Modal.confirm({
      className: classNames(modals.qsConfirmAntdModal, S.deleteConfirmModal),
      title: 'Delete activity',
      content: `Are you sure you want to delete ${hours} ${withRightPlural('hour', hours)} of ${userHour.partner.name}: ${userHour.activity.name}?`,
      cancelButtonProps: { className: modals.modalCancelBtn, type: 'text' },
      okButtonProps: { className: modals.modalOkBtn, size: 'large' },
      okText: 'Confirm',
      centered: true,
      autoFocusButton: null,
      maskClosable: true,
      icon: null,
      onOk: deleteUserHourWithoutConfirm,
    });
  };

  const deleteUserHourHandler = () => {
    if (isDeletingConfirmModalNeeded) {
      deleteUserHourConfirmation();
    } else {
      deleteUserHourWithoutConfirm();
    }
  };

  const renderInnerPanel = () => (
    <div className={classNames(S.innerPanel, {
      [S.main]: innerPanel === 'main',
      [S.replace]: innerPanel === 'replace',
    })}
    >
      <div className={S.mainPanel}>
        <MainInnerPanel
          hours={hours}
          userHour={userHour}
          isLoading={isLoading}
          setInnerPanel={setInnerPanel}
          deleteUserHourHandler={deleteUserHourHandler}
        />
      </div>
      <div className={S.replacePanel}>
        <ReplaceActivityInnerPanel
          hours={hours}
          userId={user.userId}
          userHour={userHour}
          setInnerPanel={setInnerPanel}
          onClose={onClose}
        />
      </div>
    </div>
  );

  return (
    <Modal
      title=""
      visible={isVisible}
      onCancel={onClose}
      closeIcon={<CloseIcon stroke="#9BA3AC" />}
      className={classNames(S.userHourModal, modals.mobileQsBasicAntdModal)}
      footer={[]}
    >
      <div className={S.titleWrapper}>
        <PartnerMask
          wrapperColor={userHour.partner.color}
          mask={userHour.partner.mask}
          wrapperClassName={S.activityIconWrapper}
          partnerId={userHour.partner.partnerId}
          iconColor={userHour.activity.color}
        />
        <h3 className={S.title}>
          {`${userHour.partner.name}: ${userHour.activity.name}`}
        </h3>
      </div>
      {renderInnerPanel()}
    </Modal>
  );
};

export default UserHourModal;
