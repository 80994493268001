import React, { useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { Button, Checkbox, Input, Popover, Select, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import selects from 'helpers/styles/components/selects.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import buttons from 'helpers/styles/components/buttons.module.sass';
import OverdueBadge from 'components/_shared/OverdueBadge';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import { EMPLOYEES_FILTER_OVERDUE, EMPLOYEES_FILTER_SKILL, EMPLOYEES_TIMESHEET_FILTER_OVERDUE } from 'helpers/constants/Employees/employeesConstants';
import { ReactComponent as TimeIcon } from 'helpers/icons/NavBarMenu/WorkedHours.svg';
import GeneralFiltersButton from 'components/GeneralFiltersButton/GeneralFiltersButton';
import { EXTRA_PERMISSIONS } from 'helpers/constants/_common/constants';

import { EmployeesFiltersState, ExtraPermissions } from '../../helpers/types';
import EmployeeAddNewModal from '../EmployeeAddNewModal';
import s from './helpers/styles.module.sass';

interface EmployeesFiltersProps {
  filters: EmployeesFiltersState;
  onChangeFilters: (value: string | number | boolean | number[], fieldName: keyof EmployeesFiltersState) => void;
  getEmployeesData: () => void;
}

const EmployeesFilter: React.FC<EmployeesFiltersProps> = ({ filters, onChangeFilters, getEmployeesData }) => {
  const checkboxRef = useRef<any>(null);
  const [ visible, setVisible ] = useState(false);

  const extraPermissionsAmount = useMemo(() => _.reduce(EXTRA_PERMISSIONS, (result, permission, permissionName) => {
    if (filters[permissionName as keyof ExtraPermissions]) {
      return result + 1;
    }
    return result;
  }, 0), [ filters ]);

  const handleOnSearchEmployee = _.debounce((value) => {
    onChangeFilters(value, 'fullnameContains');
  }, 1000);

  const renderInputSearch = () => (
    <Input
      className={cx(inputs.qsInput, s.employeeInput)}
      prefix={<SearchIcon style={{ fill: '#CCCCCC' }} />}
      size="large"
      onChange={e => handleOnSearchEmployee(e.currentTarget.value)}
      allowClear
      placeholder="Search employees..."
    />
  );

  const renderExtraPermissionsDropdown = () => (
    <div className={s.permissionsSelectWrapper}>
      <Popover
        placement="bottomLeft"
        trigger="click"
        className={s.permissionsSelect}
        align={{ offset: [ 0, -6 ] }}
        overlayClassName={s.permissionsSelectOverlay}
        content={_.map(EXTRA_PERMISSIONS, (permissionName, permissionKey) => (
          <Checkbox
            key={permissionName}
            checked={filters[permissionKey as keyof ExtraPermissions]}
            onChange={(event: CheckboxChangeEvent) => onChangeFilters(event.target.checked, 'hasSpecialistProfilesReadAccess')}
          >
            {permissionName}
          </Checkbox>
        ))}
      >
        <div>
          Extra permissions
          {extraPermissionsAmount > 0 && (
            <span className={cx(s.permissionCounter, 'permissionCounter')}>{extraPermissionsAmount}</span>
          )}
          <SelectIcon />
        </div>
      </Popover>
    </div>
  );

  const renderSkillTagsSelect = () => {
    const skillTagsOptions = _.map(EMPLOYEES_FILTER_SKILL, s => (
      <Select.Option
        value={s.value}
        key={s.value}
        label={s.render(filters.hasPrimarySkill)}
      >
        {s.text}
      </Select.Option>
    ));

    const onDropdownVisibleChange = (visible: boolean) => {
      if (visible) {
        return;
      }

      if (checkboxRef.current) {
        const checkboxValue = checkboxRef?.current?.state?.checked;

        if (!_.isEqual(filters.hasPrimarySkill, checkboxValue)) {
          onChangeFilters(checkboxValue, 'hasPrimarySkill');
        }
      }
    };

    return (
      <Select
        className={cx(selects.qsSelect, s.employeeSelect, { [s.skills]: true })}
        dropdownClassName={selects.selectDropdownWide}
        suffixIcon={<SelectIcon />}
        size="large"
        value={filters.skillsCountLte}
        onChange={value => onChangeFilters(value, 'skillsCountLte')}
        onDropdownVisibleChange={onDropdownVisibleChange}
        bordered={false}
        optionLabelProp="label"
        dropdownMatchSelectWidth={224}
        dropdownRender={menu => (
          <div>
            <span className={s.selectDescription}>Select skill tags quantity</span>
            {menu}
            <div className={s.selectCheckboxFooter}>
              <Checkbox
                ref={checkboxRef}
                className={s.mainSkillCheckbox}
              >
                <Typography.Text>Main skill not selected</Typography.Text>
              </Checkbox>
            </div>
          </div>
        )}
      >
        {skillTagsOptions}
      </Select>
    );
  };

  const renderSkillsOverdueSelect = () => {
    const overdueOptions = _.map(EMPLOYEES_FILTER_OVERDUE, (o, key) => (
      <Select.Option key={key} value={o.value}>
        <OverdueBadge color={o.badgeColor} ghosted={o.ghosted} />
        {o.text}
      </Select.Option>
    ));

    return (
      <div className={s.filter}>
        <FontAwesomeIcon
          className={cx(s.filterIcon, s.fontAwesomeIcon)}
          icon="layer-group"
          size="2x"
          title="Filter by last skill update"
        />
        <Select
          className={cx(selects.qsSelect, s.employeeSelect, { [s.overdue]: true })}
          dropdownClassName={selects.selectDropdownWide}
          suffixIcon={<SelectIcon />}
          size="large"
          onChange={value => onChangeFilters(value, 'skillsetLastUpdate')}
          value={filters.skillsetLastUpdate}
          bordered={false}
          dropdownMatchSelectWidth={224}
          dropdownRender={menu => (
            <div>
              <span className={s.selectDescription}>Days since the last skillset update</span>
              {menu}
            </div>
          )}
        >
          {overdueOptions}
        </Select>
      </div>
    );
  };

  const renderTimesheetOverdueSelect = () => {
    const overdueOptions = _.map(EMPLOYEES_TIMESHEET_FILTER_OVERDUE, (o, key) => (
      <Select.Option key={key} value={o.value}>
        <OverdueBadge color={o.badgeColor} ghosted={o.ghosted} />
        {o.text}
      </Select.Option>
    ));

    return (
      <div className={s.filter}>
        <TimeIcon
          className={cx(s.filterIcon, s.svgIcon)}
          title="Filter by last timesheet update"
        />
        <Select
          className={cx(selects.qsSelect, s.employeeSelect, { [s.overdue]: true })}
          dropdownClassName={selects.selectDropdownWide}
          suffixIcon={<SelectIcon />}
          size="large"
          onChange={value => onChangeFilters(value, 'timesheetLastUpdate')}
          value={filters.timesheetLastUpdate}
          bordered={false}
          dropdownMatchSelectWidth={224}
          dropdownRender={menu => (
            <div>
              <span className={s.selectDescription}>Days since the last timesheet update</span>
              {menu}
            </div>
          )}
        >
          {overdueOptions}
        </Select>
      </div>
    );
  };

  const renderAddNewButton = () => (
    <Button
      size="large"
      onClick={() => setVisible(!visible)}
      className={cx(buttons.qsButton, s.addButton)}
    >
      <div className={buttons.iconTextWrapper}>
        <FontAwesomeIcon icon={[ 'fas', 'plus-circle' ]} />
        <span>Add new</span>
      </div>
    </Button>
  );

  return (
    <div className={s.filtersWrapper}>
      {renderInputSearch()}

      <GeneralFiltersButton
        isProfilesViewerMode={false}
        onSearch={(ids) => {
          onChangeFilters(ids.entityId, 'entityId');
          onChangeFilters(ids.userId, 'userId');
          onChangeFilters(ids.partnerId, 'partnerId');
        }}
      />

      {renderExtraPermissionsDropdown()}
      {renderSkillTagsSelect()}
      {renderSkillsOverdueSelect()}
      {renderTimesheetOverdueSelect()}
      {renderAddNewButton()}

      <EmployeeAddNewModal
        getEmployeesData={getEmployeesData}
        setVisible={setVisible}
        visible={visible}
      />
    </div>
  );
};

export default EmployeesFilter;
