import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Button, Input, Radio, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import { getPartnerRequesters, getPartnerAuthors } from 'api/Partners/requests';
import radio from 'helpers/styles/components/radio.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import buttons from 'helpers/styles/components/buttons.module.sass';
import InfinityScroll from 'components/_shared/InfinityScroll';
import { ReactComponent as ArrowIcon } from 'helpers/icons/arrowIcon.svg';
import useDebounce from 'helpers/hooks/useDebounce';

import { PartnersFiltersState } from '../../helpers/types';
import s from './styles.module.sass';
import NewPartnerModal from '../NewPartnerModal';
import CreatedTimeSelect from './components/CreatedTimeSelect/CreatedTimeSelect';

interface PartnersSettingsProps {
  filters: PartnersFiltersState;
  setFilters: (filters: PartnersFiltersState) => void;
  refreshTable: () => void;
}

const PartnersSettings: React.FC<PartnersSettingsProps> = ({ filters, setFilters, refreshTable }) => {
  const [ addPartnerVisible, setAddPartnerVisible ] = useState(false);
  const [ searchValue, setSearchValue ] = useState<string | undefined>();
  
  const debounceSearchValue = useDebounce(searchValue, 600);
  
  useEffect(() => {
    if (!_.isUndefined(debounceSearchValue)) {
      setFilters({
        ...filters,
        nameContains: searchValue,
      });
    }
  }, [ debounceSearchValue ]);

  const handleOnChange = (value: boolean) => {
    setFilters({
      ...filters,
      active: value,
    });
  };

  const onVisibleChange = (status: boolean, withRefresh?: boolean) => {
    setAddPartnerVisible(status);

    if (withRefresh) {
      refreshTable();
    }
  };

  const setRequestedBy = (requesterId: number) => {
    if (requesterId) {
      setFilters({ ...filters, requesterId });
    } else {
      setFilters(_.omit(filters, 'requesterId'));
    }
  };

  const setAuthorBy = (authorId: number) => {
    if (authorId) {
      setFilters({ ...filters, authorId });
    } else {
      setFilters(_.omit(filters, 'authorId'));
    }
  };

  const renderRadio = () => (
    <div className={cx(radio.tableRadio, s.partnersRadio)}>
      <Radio.Group
        value={filters.active}
        className={s.radioWrapper}
        onChange={e => handleOnChange(e.target.value)}
      >
        <Radio
          className={radio.radioItem}
          value
        >
          Active
        </Radio>
        <Radio
          className={radio.radioItem}
          value={false}
        >
          Inactive
        </Radio>
      </Radio.Group>
    </div>
  );

  const renderInputSearch = () => (
    <Input
      className={cx(inputs.qsInput, s.partnersInput)}
      prefix={<SearchIcon style={{ fill: '#CCCCCC' }} />}
      value={searchValue}
      size="large"
      onChange={e => setSearchValue(e.currentTarget.value)}
      allowClear
      placeholder="Search partners..."
    />
  );

  const renderRequestedBySelect = () => (
    <InfinityScroll
      dropdownAlign={{ offset: [ 0, 7 ] }}
      value={filters.requesterId}
      className={s.requestedBySelect}
      bordered={false}
      width={180}
      onSelect={setRequestedBy}
      placeholder="Requested by..."
      filterOption={() => true}
      suffixIcon={<ArrowIcon />}
      defaultElementRender={() => (
        <Select.Option className={s.defaultSelectionValue} name="Requested by anyone">
          Requested by anyone
        </Select.Option>
      )}
      requestSettingsWithSearchValue={searchValue => ({
        orderBy: 'fullname',
        active: true,
        fullnameContains: searchValue,
        usernameContains: searchValue,
        emailAddressContains: searchValue,
      })}
      getter={getPartnerRequesters}
      renderItem={user => (
        <Select.Option
          key={user.userId}
          value={user.userId}
          name={user.forename}
        >
          {`${user.forename} ${user.surname}`}
        </Select.Option>
      )}
    />
  );

  const renderAddedBySelect = () => (
    <InfinityScroll
      dropdownAlign={{ offset: [ 0, 7 ] }}
      value={filters.authorId}
      className={s.requestedBySelect}
      bordered={false}
      width={180}
      onSelect={setAuthorBy}
      placeholder="Added by..."
      filterOption={() => true}
      suffixIcon={<ArrowIcon />}
      clearIcon
      defaultElementRender={() => (
        <Select.Option className={s.defaultSelectionValue} name="Added by anyone">
          Added by anyone
        </Select.Option>
      )}
      requestSettingsWithSearchValue={searchValue => ({
        orderBy: 'fullname',
        active: true,
        fullnameContains: searchValue,
        usernameContains: searchValue,
        emailAddressContains: searchValue,
      })}
      getter={getPartnerAuthors}
      renderItem={user => (
        <Select.Option
          key={user.userId}
          value={user.userId}
          name={user.forename}
        >
          {`${user.forename} ${user.surname}`}
        </Select.Option>
      )}
    />
  );

  const renderCreatedAtSelect = () => (
    <CreatedTimeSelect
      value={filters.createdAt || ''}
      onChange={(createdAt: string) => {
        if (_.isEmpty(createdAt)) {
          setFilters(_.omit(filters, 'createdAt'));
        } else {
          setFilters({ ...filters, createdAt });
        }
      }}
    />
  );

  const renderAddNewButton = () => (
    <Button
      size="large"
      className={cx(buttons.qsButton, s.addButton)}
      onClick={() => setAddPartnerVisible(true)}
    >
      <FontAwesomeIcon icon={[ 'fas', 'plus-circle' ]} />
      <span>Add new</span>
    </Button>
  );

  return (
    <div className={s.partnersSettings}>
      <div>
        { renderRadio() }
      </div>
      <div className={s.filtersWrapper}>
        { renderInputSearch() }
        { renderRequestedBySelect() }
        { renderAddedBySelect() }
        { renderCreatedAtSelect() }
        { renderAddNewButton() }
      </div>

      <NewPartnerModal
        visible={addPartnerVisible}
        onVisibleChange={onVisibleChange}
      />
    </div>
  );
};

export default PartnersSettings;
