import React from 'react';
import { Button, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import popover from 'helpers/styles/components/popover.module.sass';
import { ConvertedUserDetailedReport } from 'api/Reports/types';

import S from './styles.module.sass';

interface ThreeDotsMenuProps {
  record: ConvertedUserDetailedReport;
  notCurrentPeriodViewing: boolean;
  showNotificationModal: () => void;
}

const ThreeDotsMenu: React.FC<ThreeDotsMenuProps> = ({ showNotificationModal, notCurrentPeriodViewing, record }) => {
  const popoverContent = (
    <div className={popover.dotsPopoverItemWrapper}>
      <Button
        className={popover.dotsPopoverButton}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          showNotificationModal();
        }}
        disabled={record.periodIsClosed || notCurrentPeriodViewing}
      >
        Send notification
      </Button>
    </div>
  );
  
  return (  
    <div className={popover.dotsPopover} title="More...">
      <Popover
        content={popoverContent}
        overlayClassName={popover.dotsPopoverOverlay}
        placement="left"
        trigger="click"
      >
        <Button type="text" size="small" className="action-column-button">
          <FontAwesomeIcon className={S.threeDotsIcon} icon="ellipsis-v" />
        </Button>
      </Popover>
    </div>
  );
};

export default ThreeDotsMenu;
