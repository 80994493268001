import React from 'react';

import { EMPLOYEE_OVERDUE_RANGES, EMPLOYEE_TIMESHEET_OVERDUE_RANGES } from '../../../api/User/constants';

export interface EmployeeFilterOverdue {
  [key: string]: EmployeeFilterOverdueItem
}

export interface EmployeeFilterOverdueItem {
  text: string;
  badgeColor: string;
  ghosted?: boolean;
  value: string;
}

export const EMPLOYEES_FILTER_OVERDUE: EmployeeFilterOverdue = {
  any: {
    text: 'Any overdue',
    badgeColor: '#72798180',
    ghosted: true,
    value: '',
  },
  [EMPLOYEE_OVERDUE_RANGES.lessThan30]: {
    text: '0–29 days',
    badgeColor: '#60BF7A',
    ghosted: true,
    value: EMPLOYEE_OVERDUE_RANGES.lessThan30,
  },
  [EMPLOYEE_OVERDUE_RANGES.lessThan60]: {
    text: '30–59 days',
    badgeColor: '#60BF7A',
    value: EMPLOYEE_OVERDUE_RANGES.lessThan60,
  },
  [EMPLOYEE_OVERDUE_RANGES.lessThan90]: {
    text: '60–89 days',
    badgeColor: '#EEAC57',
    value: EMPLOYEE_OVERDUE_RANGES.lessThan90,
  },
  [EMPLOYEE_OVERDUE_RANGES.lessThan120]: {
    text: '90–119 days',
    badgeColor: '#F4536E',
    value: EMPLOYEE_OVERDUE_RANGES.lessThan120,
  },
  [EMPLOYEE_OVERDUE_RANGES.moreThan120]: {
    text: '120+ days',
    badgeColor: '#611B27',
    value: EMPLOYEE_OVERDUE_RANGES.moreThan120,
  },
};

export const EMPLOYEES_TIMESHEET_FILTER_OVERDUE: EmployeeFilterOverdue = {
  any: {
    text: 'Any overdue',
    badgeColor: '#72798180',
    ghosted: true,
    value: '',
  },
  [EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan5]: {
    text: '0–5 days',
    badgeColor: '#60BF7A',
    ghosted: true,
    value: EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan5,
  },
  [EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan10]: {
    text: '6–10 days',
    badgeColor: '#60BF7A',
    value: EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan10,
  },
  [EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan15]: {
    text: '11–15 days',
    badgeColor: '#EEAC57',
    value: EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan15,
  },
  [EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan20]: {
    text: '16–20 days',
    badgeColor: '#F4536E',
    value: EMPLOYEE_TIMESHEET_OVERDUE_RANGES.lessThan20,
  },
  [EMPLOYEE_TIMESHEET_OVERDUE_RANGES.moreThan20]: {
    text: '21+ days',
    badgeColor: '#611B27',
    value: EMPLOYEE_TIMESHEET_OVERDUE_RANGES.moreThan20,
  },
};

export const EMPLOYEES_FILTER_SKILL = {
  0: {
    text: '0 and more',
    render: (isMainSkill?: boolean) => (isMainSkill ? (
      <div className="filterSkillsWrapper">
        <span>0+ skill tags</span>
        <span className="filterSkillsWrapper_desc">+ No Main skill</span>
      </div>
    ) : '0+ skill tags'),
    value: '',
  },

  1: {
    text: 'Less than 5',
    render: (isMainSkill?: boolean) => (isMainSkill ? (
      <div className="filterSkillsWrapper">
        <span>{'5> skill tags'}</span>
        <span className="filterSkillsWrapper_desc">+ No Main skill</span>
      </div>
    ) : '5> skill tags'),
    value: 4,
  },

  2: {
    text: 'Less than 10',
    render: (isMainSkill?: boolean) => (isMainSkill ? (
      <div className="filterSkillsWrapper">
        <span>{'10> skill tags'}</span>
        <span className="filterSkillsWrapper_desc">+ No Main skill</span>
      </div>
    ) : '10> skill tags'),
    value: 9,
  },

  3: {
    text: 'Less than 20',
    render: (isMainSkill?: boolean) => (isMainSkill ? (
      <div className="filterSkillsWrapper">
        <span>{'20> skill tags'}</span>
        <span className="filterSkillsWrapper_desc">+ No Main skill</span>
      </div>
    ) : '20> skill tags'),
    value: 19,
  },
};
